import React, { useEffect, useState } from "react";
import { ILayoutTemplate } from "../../../Context/PDFEditorContext";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useUserContext } from "../../../Context/UserContext";
import { UploadTinyMce } from "../../../Services/UploadFileService";

type Props = {
  template: ILayoutTemplate;
  onUploadComplete: (url: string) => void;
};

const StaticImage = ({ template, onUploadComplete }: Props) => {
  const [loading, setLoading] = useState(false);
  var _userData = JSON.parse(window.localStorage.getItem("userData") || "");
  const [userData, setUserData] = useUserContext();
  const _sharepointSiteURL = _userData.SharepointSiteURL;
  const [imageUrl, setImageUrl] = useState<string>("");
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const fetchData = () => {
      try {
        const link = getLink(template.value);
        setImageUrl(link);
      } catch (error) {
        // Handle error
        console.error("Error fetching image link:", error);
      }
    };

    fetchData();
  }, [template.value]);

  const getLink = (val: string) => {
    if (template.value === "") {
      return "";
    }
    let imgUrl = "";
    const protocol = window.location.protocol;

    if (_sharepointSiteURL) {
      imgUrl = _sharepointSiteURL + "/" + val;
    } else {
      const _port = window.location.port;
      let _tinyURL = _userData.TinyURL;
      if (_port) {
        _tinyURL = _userData.TinyURL;
      }
      imgUrl = `${protocol}//` + _tinyURL + val;
    }

    return imgUrl !== ""
      ? imgUrl
      : "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png";
  };

  const handlePreviewClick = () => {
    setVisible(true);
  };

  const handleModalClose = () => {
    setVisible(false);
  };
  const checkFile = (file: File) => {
    setLoading(true);
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("You can only upload image files!");
    }
    return isImage;
  };

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined rev={undefined} />
      ) : (
        <PlusOutlined rev={undefined} />
      )}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      {imageUrl !== "" ? (
        <>
          <div
            className="content"
            style={{
              width: "100%",
              height: "100%",
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: template.attribute?.backgroundSize || "cover", // or 'contain' depending on your requirements
              backgroundRepeat: "no-repeat",
              backgroundPosition:
                template.attribute?.backgroundSize === "contain"
                  ? "center center"
                  : "unset",
            }}
          />
        </>
      ) : (
        <Upload
          className="static-img"
          listType="picture-card"
          beforeUpload={checkFile}
          showUploadList={false}
          onChange={async (e) => {
            if (e.file.originFileObj) {
              const uploadedFile = e.file.originFileObj;
              if (uploadedFile) {
                const file = new File([uploadedFile], uploadedFile.name, {
                  lastModified: Date.now(),
                });
                const formData = new FormData();
                formData.append("file", file);
                formData.append("Doclib", "TempAttachment");
                formData.append("docSet", "TinyImages");
                formData.append("fileDesc", "");
                formData.append("actorID", userData.EmployeeId.toString());

                var response: any = await UploadTinyMce(formData);
                console.log(response);
                if (response?.data?.result) {
                  getLink(response.data.pathUrl);
                  onUploadComplete(response.data.pathUrl);
                }
              }
            }

            setLoading(false);
          }}
          // fileList={fileList}
          style={{ height: "100%" }}
        >
          {uploadButton}
        </Upload>
      )}
    </>
  );
};

export default StaticImage;
