import moment from "moment";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import InformationComponentFix from "../../components/RequestComponents/InformationComponent/InformationComponentFix";
import { useUserContext } from "../../Context/UserContext";
import useLoading from "../../hooks/useLoading";
import { IMemoButtonModel } from "../../IRequestModel/IMemoButtonModel";
import { IMemoDetailModel } from "../../IRequestModel/IMemoDetailModel";
import { useTranslation } from "react-i18next";
import {
  GetMemoAuthorized,
  GetMemoAuthorizedViewAndPrint,
} from "../../Services/AuthorizedService";
import { formatToColumns } from "../../Helper/formatColumn";
import { dataCompany } from "../../Services/CompanyService";
import { GetAllDynamic } from "../../Services/DynamicService";
import { GetAllEmployee } from "../../Services/EmployeeService";
import { Button } from "antd";
import {
  ActionMemoPage,
  GetButtonMemoByMemoId,
  GetMemoDetail,
  GetMemoDetailOnlyById,
  GetMemoHistoryDetail,
  GetRefDocTemp,
  SetCheckAcces,
  ValidateDelegate,
} from "../../Services/MemoService";
import {
  GetTemplateByDocTypeCode,
  GetTemplateById,
  GetTemplateControlById,
  GetTemplateFromDDL,
  LoadLogic,
  SearchTemplateListEditing,
} from "../../Services/TemplateService";
import { IoMenu } from "react-icons/io5";
import { TreeSelectNewRequest } from "../../components/TreeSelectNewRequest/TreeSelectNewRequest";
import RequestSideBarElement from "./RequestSideBarElement";
import LineApprovalsComponentFix from "../../components/RequestComponents/LineApprovalsComponent/LineApprovalsComponentFix";
import AttachmentComponent from "../../components/RequestComponents/AttachmentComponent/AttachmentComponent";
import { HistoryComponent } from "../../components/RequestComponents/HistoryComponent/HistoryComponent";
import { RequestorComponent } from "../../components/RequestComponents/RequestorComponent/RequestorComponent";
import { useForm } from "react-hook-form";
import { GetAllProject } from "../../Services/ProjectService";
import { generateQuickGuid } from "../../Helper/GenerateGuid";
import { ADTitleConfiguration } from "../../Services/ConfigurationService";
import {
  GetAllMasterData,
  GetLeaveTemplateByEmpId,
  getVersionTempVC,
} from "../../Services/MasterDataService";
import { OtherButtonMemoComponent } from "../../components/MemoButton/MemoButtonetcComponent";

import { formatKeyLogicData } from "../../Helper/formatKeyLogicData";
import {
  ILogic,
  ILogicPermission,
  ILogicReferenceField,
  ILogicTypePermission,
  ILogicTypeShowHide,
  ILogicTypeReference,
  ILogicTypeSourceLoad,
  ILogicTypeReladToLoadData,
  ILogicTypeDataLineApprove,
  ILogicReferenceInformation,
  ILogicTypeSourceRelated,
} from "../../IRequestModel/ILogicModel";

import { MemoSingleButton } from "../../components/MemoButton/MemoSingleButton";
import { MemoButtonComponent } from "../../components/MemoButton/MemoButtonComponent";
import { IRequestOnSubmit } from "../../IRequestModel/CreateFormControl/IRequestOnSubmit";

import { Toast } from "primereact/toast";
import { ResponeValidation, Validation } from "../../Helper/Validation";
import { IUserModel } from "../../IRequestModel/IUserModel";
import { INumberFormula } from "../../IRequestModel/INumberFormula";
import { IAutoNumberAttibute } from "../../IRequestModel/IAutoNumberFormat";
import {
  GetRvsRunning,
  GetSaveRunningNumber,
  CanAssignMoreThanOne,
  CanRequestCommentMoreThanOne,
} from "../../Services/RequestControlService";
import { GetRolePermission } from "../../Services/RoleServices";
import { onCheckMemoPermission } from "../../Helper/CheckMemoPermission";
import {
  IApprovaReadHide,
  IListApprovalDetailsModel,
} from "../../IRequestModel/IListApprovalDetailsModel";
import { GetApprovalsByMemoIDs } from "../../Services/LineApprovalsService";
import { sorterFunc } from "../../Helper/SortingFunction";
import { genAutoNum } from "../../Helper/RequestScreenHelper";
import useAlert from "../../hooks/useAlert";
import useAdminEditCompletedMemoPermissionContext from "../../hooks/useAdminEditCompletedMemo";
import { IRolePermission } from "../../IRequestModel/IRolePermission";
import { motion, AnimatePresence } from "framer-motion";
import { isEmptyObject } from "jquery";
import { tableSelectOption } from "../../components/AntdControlComponent/TableComponent/TableComponent";

const RequestScreenFix = () => {
  const { toggleAlert } = useAlert();
  //PageSetting
  const [userData, setUserData] = useUserContext();
  const location = useLocation<any>();
  const isFirstRun = useRef(true);
  const toast = useRef<any>(null);
  const { isLoad, setLoad } = useLoading();
  const { search } = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [sidebarState, setSidebarState] = useState<boolean>(true);
  const [checkUseEffect, setCheckUseEffect] = useState<boolean>(false);
  const [checkActionPage, setCheckActionPage] = useState<string>("");
  const [checkedLeaveTemplate, setCheckedLeaveTemplate] = useState<boolean>();
  const [curPage, setCurPage] = useState<string>();
  const [selectedView, setSelectedView] = useState<string>();
  const [dataTreeSelectProps, setDataTreeSelectProps] = useState<any>({});
  const [isControlLoading, _setIsControlLoading] = useState(false);
  const [empByUserId, setEmpByUserId] = useState<any>();
  const [templateDescrip, setTemplateDescrip] = useState<any[]>([]);
  const [actionAlert, setActionAlert] = useState<any>();
  const [prevAmount, setPrevAmount] = useState<any>();

  let showLoadTimer: any = null;
  let hideLoadTimer: any = null;
  function setIsControlLoading(isShowLoad: boolean) {
    if (showLoadTimer !== null) {
      clearTimeout(showLoadTimer);
    }
    if (hideLoadTimer !== null) {
      clearTimeout(hideLoadTimer);
    }
    if (isShowLoad) {
      showLoadTimer = setTimeout(() => _setIsControlLoading(true), 10);
    } else {
      hideLoadTimer = setTimeout(() => _setIsControlLoading(false), 500);
    }
  }

  //Check Can Edit
  const [canEditDoc, setCanEditDoc] = useState<boolean>(false);
  const [canEditLineApprove, setCanEditLineApprove] = useState<boolean>(false);
  const { canEditMemo } = useAdminEditCompletedMemoPermissionContext();
  const [isCheckAccess, setIsCheckAccess] = useState<boolean>(false);

  //MemoPage Value
  const [memoId, setMemoId] = useState<any>();
  const [memoDetail, setMemoDetail] = useState<any>();
  const [listFormNames, setListFormNames] = useState<any>();
  const [lineApproval, setLineApproval] = useState<any[]>([]);
  const [listHistoryDetails, setListHistoryDetails] = useState<any[]>([]);
  const [listFileAttachDetails, setListFileAttachDetails] = useState<any[]>([]);
  const [listRefDocDetails, setListRefDocDetails] = useState<any[]>([]);
  const [isTextFromValue, setIsTextFromValue] = useState<boolean>(false);
  const [textFromValue, setTextFromValue] = useState<any>();
  //RefDoc Value
  const [refTempSelected, setRefTempSelected] = useState<any>([]);
  const [refAttribute, setRefAttribute] = useState<any>();
  const [searchRefDocData, setSearchRefDocData] = useState<any[]>([]);
  const [refOptions, setRefOptions] = useState<any[]>([]);
  const [dialogVisibleInRefTemplate, setDialogVisibleInRefTemplate] =
    useState(false);
  const [refLoading, setRefLoading] = useState<boolean>(false);
  const previousRefTempSelected = useRef<any>(null);
  const [canEditRefDoc, setCanEditRefDoc] = useState<boolean>(false);
  const { t } = useTranslation(["translation"]);
  const [refValue, setRefValue] = useState<any>();
  //MasterData Value
  const [masterLeaveSpec, setMasterLeaveSpec] = useState<any>([]);
  const [masterSignature, setMasterSignature] = useState<any>([]);
  const [masterATDLFT, setMasterATDLFT] = useState<any>([]);
  const [masterDLAttach, setMasterDLAttach] = useState<any>();
  const [finFo, setFinFo] = useState<any>([]);
  const [lRTempCode, setLRTempCode] = useState<any>();
  const [mbkOnlyU, setMbkOnlyU] = useState<any>([]);
  const [masterProjects, setMasterProjects] = useState<any>([]);
  const [masterEmployee, setMasterEmployee] = useState<any>([]);
  const [masterCompanies, setMasterCompanies] = useState<any>([]);
  const [templateListVersion, setTemplateListVersion] = useState<any>([]);
  const [selectedTemplateVersion, setSelectedTemplateVersion] = useState<any>();
  const [isTemplateVersion, setIsTemplateVersion] = useState<boolean>(false);
  const [menuButton, setMenuButton] = useState<any[]>([]);
  const [allLogic, setAllLogic] = useState<any[]>([]);
  let [jsonConditions, setJsonConditions] = useState<string>();
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
  const [isShowPdf, setIsShowPdf] = useState(true);
  const [isShowSum, setIsShowSum] = useState<boolean>(false);
  const [permission, setPermission] = useState<any>();
  const [masterDataValidField, setMasterDataValidField] = useState<any[]>([]);
  const [messageValidField, setMessageValidField] = useState<any>();
  const [duration, setDuration] = useState<number>(0);
  const [resultLogicRelated, setResultLogicRelated] = useState<any>({});
  //autoApprove
  const [requestActionMemo, setRequestActionMemo] = useState<boolean>(false);
  const [checkRequestActionMemo, setCheckRequestActionMemo] =
    useState<boolean>(false);
  //AutoControlAttibute
  const [numFormulas, setNumFormulas] = useState<INumberFormula[]>([]);
  const [autoNumFormat, setAutoNumFormat] = useState<IAutoNumberAttibute>({
    formats: [],
    showSymbol: false,
    digit: 0,
    rowIndex: -1,
    colIndex: -1,
  });

  const [buttonType, setButtonType] = useState<string>("");

  const [tableOptions, setTableOptions] = useState<tableSelectOption[]>([]);

  const previousView = useRef<any>(null);
  let previousAmount = useRef<number>(0);
  // dialog employee
  const [employeeList, setEmployeeList] = useState<any>(masterEmployee);
  const [requestDetail, setRequestDetail] = useState<any>();

  useEffect(() => {
    previousView.current = selectedView;
  }, [selectedView]);

  //Hook form
  const {
    handleSubmit,
    control,
    reset,
    register,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      items: memoDetail?.template_desc?.items,
    },
    mode: "onChange",
  });
  const [listInToAndPass, setListInToAndPass] = useState<any>({
    to: [],
    pass: [],
  });
  const [project, setProject] = useState<any>();
  const [isBranchFromADTitle, setIsBranchFromADTitle] =
    useState<boolean>(false);
  const [isADTitleToPosition, setIsADTitleToPosition] =
    useState<boolean>(false);
  const [leaveTypeTable, setLeaveTypeTable] = useState<any>([]);

  const [tableSummaries, setTableSummaries] = useState<any[]>();
  const prevRequestLineapprove = useRef<any>(null);
  //RequestorValue
  const [requestor, setRequestor] = useState<any>({});
  const [creator, setCreator] = useState<any>({});
  const [edFont, setEdFont] = useState<any>([]);
  console.log("memode", memoDetail);
  useEffect(() => {
    setLoad(false);
    const fetchMasterData = async () => {
      setLoad(true);
      const masterData = await GetAllMasterData();
      const projects = await GetAllProject();
      // const employee = await GetAllEmployee();
      const companies = await dataCompany();
      const isMultiAssign = await CanAssignMoreThanOne();
      const isMultiRequestComment = await CanRequestCommentMoreThanOne();
      sessionStorage.setItem("isMultiAssign", isMultiAssign.result);
      sessionStorage.setItem(
        "isMultiRequestComment",
        isMultiRequestComment.result
      );

      setMasterSignature([
        ...masterData
          .filter((e: any) => {
            return e.MasterType === "Signature" && e.IsActive === true;
          })
          .sort((a: any, b: any) => (Number(a.Seq) > Number(b.Seq) ? 1 : -1)),
      ]);
      setMasterDataValidField([
        ...masterData.filter((e: any) => {
          return e.MasterType === "ValidField" && e.IsActive === true;
        }),
      ]);
      setMasterDLAttach(
        masterData.filter((e: any) => {
          return e.MasterType === "DLAttach" && e.IsActive === true;
        })[0]
      );
      setFinFo(
        masterData.filter((e: any) => {
          return e.MasterType === "FINFO" && e.IsActive === true;
        })[0]
      );
      setMasterATDLFT(
        masterData.filter((e: any) => {
          return e.MasterType === "ATDLFT" && e.IsActive === true;
        })[0]
      );
      setLRTempCode(
        masterData.filter((e: any) => {
          return e.MasterType === "LRTempCode";
        })
      );
      setMbkOnlyU(
        masterData.filter((e: any) => {
          return e.MasterType === "MBKOnly" && e.IsActive === true;
        })
      );
      setMasterLeaveSpec(
        masterData.filter((e: any) => {
          return e.MasterType === "LeaveSpec" && e.IsActive === true;
        })
      );
      setEdFont(
        masterData.filter((e: any) => {
          return e.MasterType === "EdFont" && e.IsActive === true;
        })[0]
      );

      // setMasterEmployee([...employee]);
      setMasterProjects([...projects]);
      setMasterCompanies([...companies]);
      setCheckUseEffect(true);
    };
    fetchMasterData();
  }, []);
  const [defaultFont, setDefault] = useState<string>();
  const [rishFont, setrishFont] = useState<string>();
  useEffect(() => {
    if (edFont) {
      const fontFormat = edFont.Value1;
      const fontSize = edFont.Value2;
      setDefault(
        "@import url('../../../../ClientApp/public/font/font.css');body {font-family: '" +
          fontFormat +
          "', sans-serif; font-size : " +
          fontSize +
          ";}"
      );
      setrishFont(fontFormat);
    }
  }, [edFont]);
  useEffect(() => {
    detectLineApproval();
  }, [lineApproval]);

  useEffect(() => {
    //Check RefTemplate if have show
    if (listFormNames) {
      if (
        listFormNames.RefTemplate === "" ||
        listFormNames.RefTemplate === "[]"
      ) {
        setRefAttribute([]);
      } else {
        getAllRefDoc(listFormNames);
      }
    }
  }, [listFormNames]);

  const getAllRefDoc = async (listFormNames: any) => {
    if (!listFormNames?.RefDocDisplay) {
      return;
    }
    setRefLoading(true);
    let _RefID: string = "";
    if (query.get("RefID") !== undefined && query.get("RefID") !== null) {
      _RefID = query.get("RefID") || "";
    }
    const display = listFormNames?.RefDocDisplay?.split(",");
    if (!refAttribute && display.length > 0) {
      let refObject: any = {
        option: [],
        position: display[0],
        mode: display[1],
        optionLabel:
          display[2] !== "Information DocumentNo" ? display[2] : "DocumentNo",
        isDefaultLineApprove: listFormNames.IsDefaultLineApprove,
        optionValue: refValue,
        refIdOnQuery: null,
      };
      setRefAttribute({ ...refObject });
    }
    const res = await GetRefDocTemp({ template_ID: listFormNames.TemplateId });
    if (res) {
      checkRefTemplate(listFormNames, listRefDocDetails, res, _RefID);
    }
    setRefLoading(false);
  };

  const detectLineApproval = () => {
    if (lineApproval) {
      console.log(lineApproval);

      lineApproval.map((line: IListApprovalDetailsModel) => {
        if (line.signature_id && line.signature_id !== 0) {
          let _signature = masterSignature.find(
            (sig: any) => sig.MasterId === line.signature_id
          );
          if (_signature) {
            line.signature_en = _signature.Value2;
            line.signature_th = _signature.Value1;
          }
        } else {
          let _signature = masterSignature.find((sig: any) => sig.Seq === "1");
          if (_signature) {
            line.signature_en = _signature.Value2;
            line.signature_id = _signature.MasterId;
            line.signature_th = _signature.Value1;
          }
        }
      });
    }
  };

  useEffect(() => {
    if (checkUseEffect) {
      checkQuery();
    }
  }, [checkUseEffect, query]);
  useEffect(() => {
    if (checkRequestActionMemo) {
      autoApprove();
    }
  }, [requestActionMemo]);

  function autoApprove() {
    var request: IRequestOnSubmit = {
      buttonType: "approve",
      inputComment: "",
      waitingFor: "",
      waitingForId: 0,
    };
    onSubmit(memoDetail.template_desc, request);
  }
  const checkQuery = async () => {
    setLoad(true);
    let canAccess = true;
    let _templateId: number = 0;
    let _memoId: number = 0;
    let _usrId: number = 0;
    let _docTypeCode: string | null = "";
    let _isCopy: boolean = false;
    let _isPreview: boolean = false;
    let _documentNo: string = "";
    let _RefID: string = "";

    if (query.get("DocTypeCode") && !query.get("template")) {
      const _response = await GetTemplateByDocTypeCode({
        DocumentCode: query.get("DocTypeCode"),
      });
      _docTypeCode = query.get("DocTypeCode");
      _templateId = _response.TemplateId;
    }

    if (query.get("template") && !query.get("DocTypeCode")) {
      _templateId = Number(query.get("template"));
    }
    if (query.get("MemoID") && !query.get("DocumentNo")) {
      setMemoId(Number(query.get("MemoID")));
      _memoId = Number(query.get("MemoID"));

      const jsonRequest = {
        memoid: _memoId,
        EmployeeId: userData.EmployeeId,
      };

      canAccess = await onCheckPermission(_memoId);
      if (
        query.get("checkRequestActionMemo") !== null &&
        query.get("checkRequestActionMemo") !== undefined
      ) {
        setCheckRequestActionMemo(true);
      }
    }
    if (!query.get("MemoID") && query.get("DocumentNo")) {
      _documentNo = query.get("DocumentNo") || "";
    }
    if (query.get("userId")) {
      _usrId = Number(query.get("userId"));
    }
    if (query.get("isCopy") !== undefined && query.get("isCopy") !== null) {
      _isCopy = true;
    }
    if (query.get("preview") !== undefined && query.get("preview") !== null) {
      _isPreview = true;
    }
    if (query.get("RefID") !== undefined && query.get("RefID") !== null) {
      _RefID = query.get("RefID") || "";
    }

    if (canAccess) {
      getInitialData(
        _memoId,
        _templateId,
        _docTypeCode,
        _isCopy,
        _isPreview,
        _usrId,
        _documentNo,
        _RefID
      );
    } else {
      history.push("/UnAuthorize");
    }
  };
  const onCheckPermission = async (memoid: number) => {
    const request = {
      MemoId: memoid,
      RequesterId: userData.EmployeeId,
      RNameEn: userData.Email,
    };
    const response = await GetMemoAuthorized(request);
    if (response === false) {
      return false;
    } else {
      return true;
    }
  };
  const getInitialData = async (
    _memoId: number,
    _templateId: number,
    _docTypeCode: string | null,
    _isCopy: boolean,
    _isPreview: boolean,
    _usrId: number,
    _documentNo: string,
    _RefID: string
    // _templateVersionCode: string | null,
    // _versionTemplate: string | null
  ) => {
    try {
      let userDataByQuery = null;
      setListInToAndPass({
        to: [],
        pass: [],
      });
      let hasQueryUserId: boolean = false;
      if (_usrId !== 0) {
        const _employee = await GetAllEmployee();

        userDataByQuery = _employee.find(
          (_emp: any) => _emp.EmployeeId === _usrId
        );
        if (userDataByQuery) {
          hasQueryUserId = true;
        }
      }

      const requestBody = {
        Memoid: _memoId,
        TemplateId: _templateId,
        DocumentCode: _docTypeCode,
        SecretId: "",
        DocumentNo: _documentNo,
        EmployeeId: userDataByQuery
          ? userDataByQuery.EmployeeId.toString()
          : userData.EmployeeId.toString(),
        actor: userDataByQuery ? userDataByQuery : userData,
      };
      let requestDetail = await GetMemoDetail(requestBody); // beam
      setRequestDetail(requestDetail);

      const _isTextForm: boolean =
        requestDetail?.requestDetails?.listFormNames[0].IsTextForm;
      let logic = await LoadLogic(
        requestDetail?.requestDetails.memoDetail.template_id !== 0
          ? requestDetail?.requestDetails.memoDetail.template_id
          : _templateId
      );
      const canViewAndPrint =
        requestDetail?.requestDetails?.memoDetail?.Permission;
      if (canViewAndPrint?.View === "F") {
        history.push("/UnAuthorize");
      }
      if (canViewAndPrint?.Print === "F") {
        setIsShowPdf(false);
      }
      setPermission(canViewAndPrint);
      let memoButtons: any[] = [];
      if (_memoId !== 0 || (_documentNo !== null && _documentNo !== "")) {
        let memoButton: IMemoButtonModel = {
          actor: {
            EmployeeId: userData.EmployeeId,
          },
          memoid: _memoId,
        };
        memoButtons = await GetButtonMemoByMemoId(memoButton);

        const menuButtonSort = memoButtons.sort(
          (x: any, y: any) => x.sequence - y.sequence
        );
        setMenuButton(menuButtonSort);
        setCheckActionPage("edit");
        if (_isTextForm) {
          setTextFromValue(
            requestDetail.requestDetails.memoDetail.template_desc
          );
          setIsTextFromValue(true);
          getLineApproveAtFirsts(
            requestDetail.requestDetails.memoDetail,
            _isCopy
          );
        } else {
          requestDetail = {
            ...requestDetail,
            requestDetails: {
              ...requestDetail.requestDetails,
              memoDetail: {
                ...requestDetail.requestDetails.memoDetail,
                template_desc: requestDetail.requestDetails.memoDetail
                  .template_desc
                  ? {
                      items: JSON.parse(
                        requestDetail.requestDetails.memoDetail.template_desc
                      ).items.map((item: any) => {
                        return {
                          ...item,
                          layout: item.layout.map((_layout: any) => {
                            return {
                              ..._layout,
                              isShow: true,
                            };
                          }),
                        };
                      }),
                    }
                  : {},
              },
            },
          };
          let templateDescWithLogic =
            requestDetail.requestDetails.memoDetail.template_desc;
          // if (requestDetail.requestDetails.memoDetail.status === "Draft") {
          templateDescWithLogic = await prepareInitialLogic(
            logic,
            requestDetail.requestDetails.memoDetail.template_desc,
            requestDetail.requestDetails.memoDetail.status,
            requestDetail.requestDetails.memoDetail.requestor
          );
          // }

          const _template = await detechRevisionControl(
            _templateId,
            templateDescWithLogic
          );
          const autoNum = detechAutonumberFormula(_template);
          if (_template.items) {
            let _templateDesc = await initialActionFiedFormLineApprove(
              [...requestDetail.requestDetails.listApprovalDetails],
              _template.items,
              requestDetail.requestDetails.memoDetail
            );
            if (_templateDesc) {
              setDefaultTemplateDesc(_templateDesc);
            }
          }
          if (_isCopy) {
            if (autoNum.rowIndex !== -1) {
              const _control = await genAutoNum(
                _template.items,
                autoNum,
                requestDetail.requestDetails.memoDetail.template_id,
                requestDetail.requestDetails.memoDetail.status
              );
              if (_control) {
                setDefaultTemplateDesc(_control);
                let _templateDesc = await initialActionFiedFormLineApprove(
                  [...requestDetail.requestDetails.listApprovalDetails],
                  _control,
                  requestDetail.requestDetails.memoDetail
                );
                if (_templateDesc) {
                  setDefaultTemplateDesc(_templateDesc);
                }
                // reset({
                //   items: _control,
                // });
              }
            } else {
              setDefaultTemplateDesc(_template.items);
              let _templateDesc = await initialActionFiedFormLineApprove(
                [...requestDetail.requestDetails.listApprovalDetails],
                _template.items,
                requestDetail.requestDetails.memoDetail
              );
              if (_templateDesc) {
                setDefaultTemplateDesc(_templateDesc);
              }
            }
          }
          // reset({
          //   items: _template.items,
          // });

          //setDefaultTemplateDesc(_template.items);
          setIsTextFromValue(false);
        }
        if (_isCopy) {
          const mewLine =
            requestDetail?.requestDetails?.listApprovalDetails?.map(
              (e: any) => {
                e.lineid = 0;
                return e;
              }
            );
          requestDetail = {
            ...requestDetail,
            requestDetails: {
              ...requestDetail.requestDetails,
              memoDetail: {
                ...requestDetail.requestDetails.memoDetail,
                memoid: 0,
                status: "New Request",
                document_no: "Auto Generate",
                copyInformation: "Y",
                creator: userData,
                requestor: userData,
                current_approval_level: 0,
                request_date: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
                modified_date: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
                created_date: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
                modified_by: userData.EmployeeId.toString(),
                created_by: userData.EmployeeId.toString(),
              },
              listApprovalDetails: mewLine,
              listHistoryDetails: [],
            },
          };

          setCheckActionPage("add");
        }
      }
      //add
      else if (_memoId === 0) {
        const dataJson = {
          Username: userData.Username,
          DepartmentId: Number(userData.DepartmentId),
          EmployeeId: Number(userData.EmployeeId),
          Email: userData.Email,
          selectAll: false,
          DefultMode: false,
          OnlyActive: true,
        };
        dataGetInformationMethodMemoProp(
          requestDetail.requestDetails.listFormNames[0],
          requestDetail.requestDetails.memoDetail
        );
        setCheckActionPage("add");

        if (_isTextForm) {
          setTextFromValue(
            requestDetail.requestDetails.listFormNames[0].TextForm
          );
          setIsTextFromValue(true);
          getLineApproveAtFirsts(
            requestDetail.requestDetails.memoDetail,
            _isCopy
          );
        } else {
          requestDetail = {
            ...requestDetail,
            requestDetails: {
              ...requestDetail.requestDetails,
              memoDetail: {
                ...requestDetail.requestDetails.memoDetail,
                template_desc: requestDetail.requestDetails.listFormNames[0]
                  .AdvanceForm
                  ? {
                      items: JSON.parse(
                        requestDetail.requestDetails.listFormNames[0]
                          .AdvanceForm
                      ).items.map((item: any) => {
                        return {
                          ...item,
                          layout: item.layout.map((_layout: any) => {
                            return {
                              ..._layout,
                              isShow: true,
                            };
                          }),
                        };
                      }),
                    }
                  : {},
              },
            },
          };

          const templateDescWithLogic = await prepareInitialLogic(
            logic,
            requestDetail.requestDetails.memoDetail.template_desc,
            requestDetail.requestDetails.memoDetail.status,
            requestDetail.requestDetails.memoDetail.requestor
          );

          const _template = await detechRevisionControl(
            _templateId,
            templateDescWithLogic
          );
          const autoNum = detechAutonumberFormula(_template);

          if (
            requestDetail.requestDetails.memoDetail.status === "New Request" ||
            _isCopy
          ) {
            if (autoNum.rowIndex !== -1) {
              const _control = await genAutoNum(
                _template.items,
                autoNum,
                requestDetail.requestDetails.memoDetail.template_id,
                requestDetail.requestDetails.memoDetail.status
              );
              if (_control) {
                setDefaultTemplateDesc(_control);
                let _templateDesc = await initialActionFiedFormLineApprove(
                  [...requestDetail.requestDetails.listApprovalDetails],
                  _control,
                  requestDetail.requestDetails.memoDetail
                );
                if (_templateDesc) {
                  setDefaultTemplateDesc(_templateDesc);
                  getLineApproveAtFirsts(
                    requestDetail.requestDetails.memoDetail,
                    _isCopy,
                    _templateDesc
                  );
                }
              }
            } else {
              setDefaultTemplateDesc(_template.items);
              let _templateDesc = await initialActionFiedFormLineApprove(
                [...requestDetail.requestDetails.listApprovalDetails],
                _template.items,
                requestDetail.requestDetails.memoDetail
              );
              if (_templateDesc) {
                setDefaultTemplateDesc(_templateDesc);
                getLineApproveAtFirsts(
                  requestDetail.requestDetails.memoDetail,
                  _isCopy,
                  _templateDesc
                );
              }
            }
          }
          setIsTextFromValue(false);
        }
      }

      detechFormulas(
        requestDetail.requestDetails.memoDetail.template_id,
        requestDetail.requestDetails.memoDetail.template_desc
      );
      if (_isPreview) {
        setCheckActionPage("preview");
      }
      if (hasQueryUserId) {
        requestDetail.requestDetails.memoDetail.creator = userDataByQuery;
        requestDetail.requestDetails.memoDetail.requestor = userDataByQuery;
      }
      checkTemplateVersion(
        requestDetail.requestDetails.listFormNames[0],
        requestDetail.requestDetails.memoDetail
      );
      checkState(
        requestDetail.requestDetails.memoDetail.status,
        requestDetail.requestDetails.listFormNames[0],
        memoButtons
      );

      checkLeaveRequestTemplate(
        requestDetail.requestDetails.memoDetail.template_code
      );
      checkDataAndSetFunction(requestDetail.requestDetails.memoDetail);
      replaceTitleInfo(requestDetail.requestDetails.memoDetail);

      setMemoDetail(requestDetail.requestDetails.memoDetail);
      setRequestor(requestDetail.requestDetails.memoDetail.requestor);
      setCreator(requestDetail.requestDetails.memoDetail.creator);

      setLineApproval([...requestDetail.requestDetails.listApprovalDetails]);

      setListHistoryDetails([
        ...requestDetail.requestDetails.listHistoryDetails,
      ]);
      setListFileAttachDetails([
        ...requestDetail.requestDetails.listFileAttachDetails,
      ]);

      setListRefDocDetails([...requestDetail.requestDetails.listRefDocDetails]);
      setListFormNames(requestDetail.requestDetails.listFormNames[0]);

      createTableSums(requestDetail.requestDetails.memoDetail.template_desc);
      // setRefTempSelected([]);
      setAllLogic([...logic]);
      setSelectedView("2");
      setCurPage("2");
      setLoad(false);
      if (isFirstRun.current) {
        isFirstRun.current = false;
      }
    } catch (error) {
      setLoad(false);
      console.error("Request=>error", error);
    }
    setRequestActionMemo(true);
  };

  const setDefaultTemplateDesc = (template: any) => {
    let templateItems = template;

    if (templateItems) {
      for (let i = 0; i < templateItems.length; i++) {
        const item = templateItems[i];
        for (let j = 0; j < item.layout.length; j++) {
          const layout = item.layout[j];
          const templateType = layout.template.type;

          if (
            templateType === "t" ||
            templateType === "ta" ||
            templateType === "c"
          ) {
            if (!layout.data.value) {
              templateItems[i].layout[j].data.value =
                layout.template.attribute.default;
            }
          } else if (templateType === "dd") {
            if (
              !layout.data.value &&
              templateItems[i].layout[j].template.attribute.items &&
              templateItems[i].layout[j].template.attribute.items.length > 0
            ) {
              templateItems[i].layout[j].data.value =
                layout.template.attribute.items[0].item;
            }
            // DefaultCalendar
          } else if (templateType === "d") {
            if (!layout.data.value && layout.template.attribute.default != "") {
              if (layout.template.attribute.defaultType === "Days") {
                let defaultDate = parseInt(layout.template.attribute.default);
                let defValue = moment(Date.now())
                  .add(defaultDate, "days")
                  .format("DD MMM YYYY");
                layout.data.value = defValue;
              }
            }
          }
        }
      }
    }
    reset({
      items: templateItems,
    });
    return {
      items: templateItems,
    };
  };
  useEffect(() => {}, [prevAmount]);
  const getLineApproveForAmount = async (
    amount: number,
    jsonCondition?: any,
    logicType?: string,
    template?: any,
    _requestor?: any
  ) => {
    let amountToUse = amount !== undefined ? amount : prevAmount;
    try {
      // testty
      if (amount !== undefined || amount === 0 || amount === "") {
        setPrevAmount(amount);
      }
      console.log("prevAmount", prevAmount);

      if (logicType === "ref") {
        setMemoDetail((prevState: any) => ({
          ...prevState,
          amount: Number(amountToUse).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }),
        }));
      } else if (!isNaN(amountToUse) && !logicType && logicType !== "ref") {
        setMemoDetail((prevState: any) => ({
          ...prevState,
          amount: Number(amountToUse).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }),
        }));
      }
      let isSummary =
        !logicType && isShowSum && amount != previousAmount.current;
      if (listFormNames.IsTextForm && isShowSum) {
        const dataJson = {
          employee: _requestor ? _requestor : memoDetail.requestor,
          ComCode: memoDetail.company_id,
          JsonCondition: jsonCondition ? jsonCondition : jsonConditions,
          templateForm: {
            ...memoDetail,
            TemplateApproveId: 0,
            template_desc: JSON.stringify(memoDetail.template_desc),
          },
          lstTRNLineApprove: [],
          Amount: Number(amountToUse),
        };

        const responseDataLineApprove = await onLoadDataLiveApprove(dataJson);
        if (responseDataLineApprove) {
          setLineApproval([...responseDataLineApprove]);
        }
      } else if (logicType || isSummary || amountToUse === 0) {
        if (
          (memoDetail &&
            (template?.type === "c" ||
              template?.type === "tb" ||
              ((template?.type === "t" ||
                template?.type === "dd" ||
                template?.type === "r" ||
                template?.type === "cb") &&
                logicType === "datalineapprove"))) ||
          logicType === "requestor" ||
          logicType === "reset"
        ) {
          setIsControlLoading(true);

          let doa: any;
          let loc: any;
          let spc: any;
          let dtp: any;
          if (jsonCondition) {
            jsonConditions = jsonCondition;
          }
          const dataJson = {
            employee: _requestor ? _requestor : memoDetail.requestor,
            ComCode: memoDetail.company_id,
            JsonCondition: jsonConditions,
            templateForm: {
              ...memoDetail,
              TemplateApproveId: 0,
              template_desc: JSON.stringify(memoDetail.template_desc),
            },
            lstTRNLineApprove: [],
            Amount: amountToUse,
          };
          const dataJsonApproverType = {
            TemplateId: memoDetail.template_id,
          };

          const approverType = await onLoadLiveApproveType(
            dataJsonApproverType
          );

          if (approverType) {
            doa = approverType.find((line: any) => line.ApproveType === 21);
            loc = approverType.find((line: any) => line.ApproveType === 19);
            spc = approverType.find((line: any) => line.ApproveType === 20);
            dtp = approverType.find((line: any) => line.ApproveType === 22);
          }

          if ((isSummary && doa) || !!logicType) {
            const responseDataLineApprove = await onLoadDataLiveApprove(
              dataJson
            );

            if (responseDataLineApprove) {
              console.log(
                "responseDataLineApprove",
                responseDataLineApprove,
                dataJson,
                isSummary
              );

              if (doa || loc || spc || dtp || logicType === "reset") {
                if (logicType === "reset") {
                  let data: any[] = [
                    ...lineApproval.slice(0, memoDetail.current_approval_level),
                    ...responseDataLineApprove.map((e: any) => {
                      e.sequence =
                        e.sequence + memoDetail.current_approval_level;
                      return e;
                    }),
                  ];

                  setLineApproval([...data]);
                } else {
                  setLineApproval([...responseDataLineApprove]);
                }
              } else if (!(doa || loc || spc || dtp) && jsonCondition) {
                setLineApproval([...responseDataLineApprove]);
              }
            } else {
            }
          }

          setIsControlLoading(false);
        }
        if (jsonCondition) {
          setJsonConditions(jsonCondition);
        }
      } else {
        previousAmount.current = amount;
      }
    } catch (error) {
      console.error("LineApprove=>error", error);
    }
  };

  const getLineApproveAtFirsts = async (
    _memoDetail: any,
    _isCopy: boolean,
    _templateDesc?: any
  ) => {
    try {
      if (_memoDetail.memoid === 0 && !_isCopy) {
        const dataJson = {
          employee: _memoDetail.requestor,
          ComCode: _memoDetail.company_id,
          JsonCondition: jsonConditions,
          templateForm: {
            ..._memoDetail,
            TemplateApproveId: 0,
            template_desc: JSON.stringify(_memoDetail.template_desc),
          },
          lstTRNLineApprove: [],
          Amount: 0,
        };

        const responseDataLineApprove = await onLoadDataLiveApprove(dataJson);
        setLineApproval([...responseDataLineApprove]);
        let tempdescItem = _templateDesc;
        if (tempdescItem) {
          if (responseDataLineApprove.length > 0) {
            let _templateDesc = await initialActionFiedFormLineApprove(
              [...responseDataLineApprove],
              tempdescItem,
              _memoDetail
            );
            if (_templateDesc) {
              setDefaultTemplateDesc(_templateDesc);
            }
          }
        }
      }
    } catch (error) {
      console.error("LineApprove=>error", error);
    }
  };

  const onLoadDataLiveApprove = async (dataJson: any) => {
    const respone = await fetch(
      "api/TemplateList/TemplateByid/LoadLogic/GetLoadLineApproveFormControl",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataJson),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err: any) => console.error({ errerrerr: err }));
    return respone;
  };

  const onLoadLiveApproveType = async (dataJson: any) => {
    const respone = await fetch("api/LineApprove/GetLineApproveType", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJson),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err: any) => console.error({ errerrerr: err }));
    return respone;
  };

  const initialActionFiedFormLineApprove = async (
    dataLineApprove: any,
    _templateDesc: any,
    _memoDetail: any
  ) => {
    const _datalineapprove = dataLineApprove;
    // template setting readonly
    const currentApprovals = _datalineapprove.find(
      (e: any) =>
        e.sequence === _memoDetail.current_approval_level &&
        e.emp_id === userData.EmployeeId
    );

    if (
      !currentApprovals ||
      currentApprovals.ColumnJsonCondValue == null ||
      currentApprovals.ColumnJsonCondValue == undefined ||
      currentApprovals.ColumnJsonCondValue == ""
    ) {
      return _templateDesc;
    }

    let action: any;
    let isShow: boolean = false;
    let showTemplate: {
      isShow: boolean;
      pos: {
        row: number;
        col: number;
        tableCols?: number[];
        notUseCols?: number[];
      }[];
      readonly?: boolean;
      notInPos: { row: number; col: number }[];
    } = { isShow: false, pos: [], notInPos: [] };
    if (_memoDetail.current_approval_level === 0) {
      for (let i = 0; i < _datalineapprove.length; i++) {
        if (
          _datalineapprove[i].ColumnJsonCondValue !== null ||
          _datalineapprove[i].ColumnJsonCondValue !== undefined ||
          _datalineapprove[i].ColumnJsonCondValue !== ""
        ) {
          action = _datalineapprove[i].ColumnJsonCondValue;
          const jsonValue: IApprovaReadHide =
            action && action.length > 0 && JSON.parse(action);
          if (jsonValue) {
            if (
              _memoDetail.status === "New Request" ||
              _memoDetail.status === "Rework"
            ) {
              if (jsonValue.isRequester === "Y") {
                if (_memoDetail.status === "Rework") {
                  for (let x = 0; x < _templateDesc.length; x++) {
                    let layout = _templateDesc[x].layout;
                    for (let y = 0; y < layout.length; y++) {
                      let template = layout[y].template;
                      if (template.type === "tb") {
                        for (
                          let z = 0;
                          z <
                          _templateDesc[x].layout[y].template.attribute.column
                            .length;
                          z++
                        ) {
                          let column =
                            _templateDesc[x].layout[y].template.attribute
                              .column[z];
                          column.control.template.attribute.readonly = "N";
                          column.isShow = true;
                        }
                        _templateDesc[x].layout[y].isShow = true;
                      } else {
                        if (_templateDesc[x].layout[y]) {
                          if (
                            _templateDesc[x].layout[y].template.type !== "l" &&
                            _templateDesc[x].layout[y].template.type !== "em"
                          ) {
                            if (_templateDesc[x].layout[y].template.attribute) {
                              _templateDesc[x].layout[
                                y
                              ].template.attribute.readonly = "N";
                            }
                            _templateDesc[x].layout[y].isShow = true;
                          } else {
                            _templateDesc[x].layout[y].isShow = true;
                          }
                        } else {
                          _templateDesc[x].layout[y].isShow = isShow;
                        }
                      }
                    }
                  }
                }
                for (let x = 0; x < _templateDesc.length; x++) {
                  const layout = _templateDesc[x].layout;
                  if (jsonValue.fieldaction) {
                    if (jsonValue.action === "readonly") {
                      showTemplate.readonly = true;
                      isShow = true;
                    } else {
                      showTemplate.readonly = false;
                      isShow = false;
                    }
                    for (let k = 0; k < jsonValue.fieldaction.length; k++) {
                      const field = jsonValue.fieldaction[k];
                      for (let j = 0; j < layout.length; j++) {
                        const templateLayouts = layout[j];

                        const tbCol = field.label.split(" : ");
                        if (tbCol[1] === templateLayouts.template.label) {
                          if (field.type === "tb") {
                            let targetCols: number[] = [];
                            let notTargetCols: number[] = [];

                            templateLayouts.template.attribute.column.forEach(
                              (e: any, colIndex: number) => {
                                if (tbCol[2] === e.control.template.label) {
                                  targetCols?.push(colIndex);
                                } else {
                                  notTargetCols.push(colIndex);
                                }
                              }
                            );
                            showTemplate.pos.push({
                              col: j,
                              row: x,
                              tableCols: targetCols,
                              notUseCols: notTargetCols,
                            });
                          } else {
                            showTemplate.pos.push({
                              col: j,
                              row: x,
                            });
                          }
                        } else {
                          showTemplate.notInPos.push({
                            col: j,
                            row: x,
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      for (let x = 0; x < _templateDesc.length; x++) {
        let layout = _templateDesc[x].layout;
        for (let y = 0; y < layout.length; y++) {
          let template = layout[y].template;
          if (template.type === "tb") {
            for (
              let z = 0;
              z < _templateDesc[x].layout[y].template.attribute.column.length;
              z++
            ) {
              let column =
                _templateDesc[x].layout[y].template.attribute.column[z];
              column.control.template.attribute.readonly = "N";
              column.isShow = true;
            }
            _templateDesc[x].layout[y].isShow = true;
          } else {
            if (_templateDesc[x].layout[y]) {
              if (
                _templateDesc[x].layout[y].template.type !== "l" &&
                _templateDesc[x].layout[y].template.type !== "em"
              ) {
                if (_templateDesc[x].layout[y].template.attribute) {
                  _templateDesc[x].layout[y].template.attribute.readonly = "N";
                }
                _templateDesc[x].layout[y].isShow = true;
              } else {
                _templateDesc[x].layout[y].isShow = true;
              }
            } else {
              _templateDesc[x].layout[y].isShow = isShow;
            }
          }
        }
      }
      if (currentApprovals) {
        if (
          currentApprovals.ColumnJsonCondValue !== null ||
          currentApprovals.ColumnJsonCondValue !== undefined ||
          currentApprovals.ColumnJsonCondValue !== ""
        ) {
          action = currentApprovals.ColumnJsonCondValue;
          const jsonValue: IApprovaReadHide =
            action && action.length > 0 && JSON.parse(action);
          if (jsonValue) {
            const dataJson = {
              TemplateId: _memoDetail.template_id,
              EmployeeId: userData.EmployeeId,
            };
            const isDelegate = await ValidateDelegate(dataJson);

            if (currentApprovals || isDelegate) {
              for (let x = 0; x < _templateDesc.length; x++) {
                const layout = _templateDesc[x].layout;
                if (jsonValue.fieldaction) {
                  if (jsonValue.action === "readonly") {
                    showTemplate.readonly = true;
                    isShow = true;
                  } else {
                    showTemplate.readonly = false;
                    isShow = false;
                  }
                  for (let k = 0; k < jsonValue.fieldaction.length; k++) {
                    const field = jsonValue.fieldaction[k];
                    for (let j = 0; j < layout.length; j++) {
                      const templateLayouts = layout[j];

                      const tbCol = field.label.split(" : ");
                      if (tbCol[1] === templateLayouts.template.label) {
                        if (field.type === "tb") {
                          let targetCols: number[] = [];
                          let notTargetCols: number[] = [];

                          templateLayouts.template.attribute.column.forEach(
                            (e: any, colIndex: number) => {
                              if (tbCol[2] === e.control.template.label) {
                                targetCols?.push(colIndex);
                              } else {
                                notTargetCols.push(colIndex);
                              }
                            }
                          );
                          showTemplate.pos.push({
                            col: j,
                            row: x,
                            tableCols: targetCols,
                            notUseCols: notTargetCols,
                          });
                        } else {
                          showTemplate.pos.push({
                            col: j,
                            row: x,
                          });
                        }
                      } else {
                        showTemplate.notInPos.push({
                          col: j,
                          row: x,
                        });
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (showTemplate.pos.length > 0) {
      if (_memoDetail.status !== "New Request") {
        for (let i = 0; i < showTemplate.notInPos.length; i++) {
          const pos = showTemplate.notInPos[i];
          if (_templateDesc[pos.row].layout[pos.col].template.type === "tb") {
            for (
              let x = 0;
              x <
              _templateDesc[pos.row].layout[pos.col].template.attribute.column
                .length;
              x++
            ) {
              let column =
                _templateDesc[pos.row].layout[pos.col].template.attribute
                  .column[x];
              column.control.template.attribute.readonly = "N";
              column.isShow = true;
            }

            _templateDesc[pos.row].layout[pos.col].isShow = true;
          } else {
            if (_templateDesc[pos.row].layout[pos.col]) {
              if (
                _templateDesc[pos.row].layout[pos.col].template.type !== "l" &&
                _templateDesc[pos.row].layout[pos.col].template.type !== "em"
              ) {
                if (_templateDesc[pos.row].layout[pos.col].template.attribute) {
                  _templateDesc[pos.row].layout[
                    pos.col
                  ].template.attribute.readonly = "N";
                }
                _templateDesc[pos.row].layout[pos.col].isShow = true;
              } else {
                _templateDesc[pos.row].layout[pos.col].isShow = true;
              }
            } else {
              _templateDesc[pos.row].layout[pos.col].isShow = isShow;
            }
          }
        }
      } else {
        for (let i = 0; i < showTemplate.pos.length; i++) {
          const pos = showTemplate.pos[i];
          if (_templateDesc[pos.row].layout[pos.col].template.type === "tb") {
            if (pos.tableCols?.length === 0) {
              _templateDesc[pos.row].layout[pos.col].isShow = true;
            }
            _templateDesc[pos.row].layout[pos.col].template.attribute.column =
              _templateDesc[pos.row].layout[
                pos.col
              ].template.attribute.column.map(
                (column: any, colIndex: number) => {
                  if (pos.notUseCols?.includes(colIndex)) {
                    column.control.template.attribute.readonly = "N";
                    column.isShow = true;
                  }
                  return column;
                }
              );
          } else {
            if (
              _templateDesc[pos.row].layout[pos.col].template.type !== "l" &&
              _templateDesc[pos.row].layout[pos.col].template.type !== "em"
            ) {
              _templateDesc[pos.row].layout[
                pos.col
              ].template.attribute.readonly = "N";
              _templateDesc[pos.row].layout[pos.col].isShow = true;
            } else {
              _templateDesc[pos.row].layout[pos.col].isShow = true;
            }
          }
        }
      }
      for (let i = 0; i < showTemplate.pos.length; i++) {
        const pos = showTemplate.pos[i];
        if (_templateDesc[pos.row].layout[pos.col].template.type === "tb") {
          if (pos.tableCols?.length === 0) {
            _templateDesc[pos.row].layout[pos.col].isShow = isShow;
          }
          _templateDesc[pos.row].layout[pos.col].template.attribute.column =
            _templateDesc[pos.row].layout[
              pos.col
            ].template.attribute.column.map((column: any, colIndex: number) => {
              if (pos.tableCols?.includes(colIndex)) {
                if (showTemplate.readonly) {
                  column.control.template.attribute.readonly = "Y";
                }
                column.isShow = isShow;
              }
              return column;
            });
        } else {
          if (
            _templateDesc[pos.row].layout[pos.col].template.type !== "l" &&
            _templateDesc[pos.row].layout[pos.col].template.type !== "em"
          ) {
            if (showTemplate.readonly) {
              _templateDesc[pos.row].layout[
                pos.col
              ].template.attribute.readonly = "Y";
              _templateDesc[pos.row].layout[pos.col].isShow = true;
            } else {
              _templateDesc[pos.row].layout[pos.col].isShow = isShow;
            }
          } else {
            _templateDesc[pos.row].layout[pos.col].isShow = isShow;
          }
        }
      }
    }
    return _templateDesc;
  };

  const detechFormulas = async (_templateId: number, templateDesc: any) => {
    let _formulas: any[] = [];
    let isSummary: boolean = false;
    let total = 0;
    for (let i = 0; i < templateDesc?.items?.length; i++) {
      const templateItems = templateDesc?.items[i];
      for (let j = 0; j < templateItems.layout.length; j++) {
        const layout = templateItems.layout[j];
        let formu: INumberFormula;
        if (layout.template.type === "c") {
          if (
            layout.template.attribute.formula !== "" &&
            layout.template.attribute.formula !== null
          ) {
            formu = {
              formula: layout.template.attribute.formula,
              rowIndex: i,
              colIndex: j,
            };
            _formulas.push(formu);
          }
          if (layout.template.attribute.summary === "Y") {
            isSummary = true;
          }
        } else if (layout.template.type === "tb") {
          layout.template.attribute.column.forEach(
            (col: any, colIndex: number) => {
              if (col.control.template.type === "c") {
                if (col.control.template.attribute.summary === "Y") {
                  isSummary = true;
                }
              }
            }
          );
        }
      }
    }

    const dataJsonApproverType = {
      TemplateId: _templateId,
    };

    const approverType = await onLoadLiveApproveType(dataJsonApproverType);

    if (approverType) {
      const doa = approverType.find((e: any) => e.ApproveType === 21);
      if (doa) {
        isSummary = true;
      }
    }

    setIsShowSum(isSummary);
    setNumFormulas([..._formulas]);
  };

  const detechAutonumberFormula = (templateDesc: any) => {
    let _autoFormats: IAutoNumberAttibute = {
      formats: [],
      showSymbol: true,
      digit: 0,
      rowIndex: -1,
      colIndex: -1,
    };

    try {
      templateDesc.items.forEach((item: any, rowIdx: number) => {
        item.layout.forEach((layout: any, colIdx: number) => {
          if (layout.template.type === "an") {
            if (layout.template.attribute) {
              layout.template.attribute.formats.map((formats: any) => {
                for (let i = 0; i < formats.format.length; i++) {
                  if (formats.format[i]) {
                    formats.format.map((f: any) => {
                      if (f.condition === "Split") {
                      }
                    });
                  }
                }
              });
            }
            _autoFormats.formats = layout.template.attribute.formats;
            _autoFormats.showSymbol = !layout.template.alter.includes("|");
            _autoFormats.digit = layout.template.digit;
            _autoFormats.rowIndex = rowIdx;
            _autoFormats.colIndex = colIdx;
          }
        });
      });

      setAutoNumFormat(_autoFormats);
      return _autoFormats;
    } catch (error) {
      console.log("auto=>error", error);
      return _autoFormats;
    }
  };

  const detechRevisionControl = async (_templateId: any, templateDesc: any) => {
    try {
      let requestBody: any = {};
      let items: any[] = [];
      let rowIndex = -1;
      let colIndex = -1;

      templateDesc.items.forEach((item: any, rowIdx: number) => {
        item.layout.forEach((layout: any, colIdx: number) => {
          if (layout.template.type === "rvs") {
            requestBody.TemplateId = _templateId;
            requestBody.RefId = 0;
            requestBody.Digit = layout.template.attribute.digit;
            requestBody.Labelrevision = layout.template.label;
            requestBody.Alter = layout.template.alter;

            if ((layout.template.attribute.conditions || []).length > 0) {
              layout.template.attribute.conditions.map((con: any) => {
                templateDesc.items.map((item: any, rowIdx: number) => {
                  item.layout.map((layout: any, colIdx: number) => {
                    if (con.label === layout.template.label) {
                      items.push({
                        Label: con.label,
                        value: layout.data.value,
                      });
                    }
                  });
                });
              });
            }

            requestBody.MemoId = null;
            requestBody.Itemlabel = items;
            rowIndex = rowIdx;
            colIndex = colIdx;
          }
        });
      });

      if (rowIndex !== -1 && colIndex !== -1) {
        const revision = await GetRvsRunning(requestBody);
        if (
          revision.item >
          templateDesc.items[rowIndex].layout[colIndex].data.value
        ) {
          templateDesc.items[rowIndex].layout[colIndex].data.value =
            revision.item;
        }
      }

      return templateDesc;
    } catch (error) {
      console.log("rvs=>error", error);
    }
  };

  const prepareInitialLogic = async (
    logics: ILogic[],
    templateDesc: any,
    memoStatus?: string,
    requestor?: any
  ) => {
    let dataGroup: any = [];
    let dataSourceLoadGroup: any[] = [];
    let dataSourceRelated: any[] = [];
    let dataSourceRelatedToLoadGroup: any[] = [];
    console.log("logiuc=>templateDesc", templateDesc);

    let eiei = JSON.stringify(templateDesc);

    let _templateDesc = JSON.parse(eiei);

    _templateDesc = {
      items: _templateDesc?.items?.map((item: any) => {
        return {
          ...item,
          layout: item.layout.map((_layout: any) => {
            return {
              ..._layout,
              isShow: true,
            };
          }),
        };
      }),
    };
    for (let i = 0; i < logics?.length; i++) {
      const logic = logics[i];
      console.log("logic=>", logic);

      if (logic.logictype === "datasourceload") {
        const jsonValue: any =
          logic.jsonvalue &&
          logic.jsonvalue.length > 0 &&
          JSON.parse(logic.jsonvalue);

        for (let i = 0; i < _templateDesc.items.length; i++) {
          const templateItems = _templateDesc.items[i];
          for (let j = 0; j < templateItems.layout.length; j++) {
            const _layout = templateItems.layout[j];
            if (_layout.template.type !== "tb") {
              if (_layout.template.label === jsonValue.label) {
                dataSourceLoadGroup.push({
                  isInTable: false,
                  col: j,
                  row: i,
                  data: logic.logicid,
                  jsonValue: logic.jsonvalue,
                });
              }
            } else {
              for (
                let k = 0;
                k < _layout.template.attribute.column.length;
                k++
              ) {
                const column = _layout.template.attribute.column[k];
                if (column.label === jsonValue.label) {
                  console.log({ logic });
                  dataSourceLoadGroup.push({
                    isInTable: true,
                    col: j,
                    row: i,
                    tableColumn: k,
                    data: logic.logicid,
                    jsonValue: logic.jsonvalue,
                  });
                }
              }
            }
          }
        }
      } else if (logic.logictype === "role") {
        let showTemplate: {
          isShow: boolean;
          pos: {
            row: number;
            col: number;
            tableCols?: number[];
            notUseCols?: number[];
          }[];
          readonly?: boolean;
          notInPos: { row: number; col: number }[];
        } = { isShow: false, pos: [], notInPos: [] };

        const jsonValue: ILogicTypeShowHide =
          logic.jsonvalue &&
          logic.jsonvalue.length > 0 &&
          JSON.parse(logic.jsonvalue);

        if (jsonValue.action !== "showpdf") {
          if (jsonValue.roleids && jsonValue.roleids.length > 0) {
            let userRole: IRolePermission[] = [];
            let roles: IRolePermission[] = await GetRolePermission();
            roles.filter((r) => r.IsDelete === false);
            for (let i = 0; i < roles.length; i++) {
              const role = roles[i];
              if (role.EmployeeId === userData.EmployeeId) {
                userRole.push(role);
              }
            }
            const isShow = onCheckUserRolePermissionInLogic(
              userRole,
              jsonValue.roleids
            );

            if (jsonValue.action === "readonly") {
              showTemplate.readonly = true;
            }
            showTemplate.isShow = isShow;
            console.log({ action: jsonValue.action, showTemplate });

            if (jsonValue.fieldaction) {
              for (let k = 0; k < jsonValue.fieldaction.length; k++) {
                const field = jsonValue.fieldaction[k];
                for (let i = 0; i < _templateDesc.items.length; i++) {
                  const templateItems = _templateDesc.items[i];
                  for (let j = 0; j < templateItems.layout.length; j++) {
                    const templateLayouts = templateItems.layout[j];
                    if (field.lable === templateLayouts.template.label) {
                      if (field.type === "tb") {
                        let targetCols: number[] = [];
                        let notTargetCols: number[] = [];

                        templateLayouts.template.attribute.column.forEach(
                          (e: any, colIndex: number) => {
                            const selectedCol = field.column?.find(
                              (col) => col.Key === e.control.template.label
                            );
                            if (selectedCol) {
                              targetCols?.push(colIndex);
                            } else {
                              notTargetCols.push(colIndex);
                            }
                          }
                        );
                        showTemplate.pos.push({
                          row: i,
                          col: j,
                          tableCols: targetCols,
                          notUseCols: notTargetCols,
                        });
                      } else {
                        showTemplate.pos.push({
                          row: i,
                          col: j,
                        });
                      }
                    } else {
                      showTemplate.notInPos.push({
                        row: i,
                        col: j,
                      });
                    }
                  }
                }
              }
            }
            console.log({ showTemplate });

            if (showTemplate.pos.length > 0) {
              console.log("logic=>showTemplate", showTemplate);
              for (let i = 0; i < showTemplate.pos.length; i++) {
                const pos = showTemplate.pos[i];
                if (
                  _templateDesc.items[pos.row].layout[pos.col].template.type ===
                  "tb"
                ) {
                  _templateDesc.items[pos.row].layout[pos.col].isShow = true;
                  _templateDesc.items[pos.row].layout[
                    pos.col
                  ].template.attribute.column = _templateDesc.items[
                    pos.row
                  ].layout[pos.col].template.attribute.column.map(
                    (column: any, colIndex: number) => {
                      if (pos.tableCols?.includes(colIndex)) {
                        if (showTemplate.readonly) {
                          column.control.template.attribute.require = "Y";
                        }
                        column.isShow = isShow;
                        console.log(column);
                      }
                      return column;
                    }
                  );
                } else {
                  _templateDesc.items[pos.row].layout[pos.col].isShow = isShow;
                  console.log(
                    _templateDesc.items[pos.row].layout[pos.col].template.type
                  );
                }
              }
            }
          }
        }
      } else if (logic.logictype === "reference") {
        let responseData: {
          loadTo: any[];
          data: any[];
          row: number;
          col: number;
        } | null = null;
        JSON.parse(logic.jsonvalue);
        const jsonValue: ILogicTypeReference =
          logic.jsonvalue &&
          logic.jsonvalue.length > 0 &&
          JSON.parse(logic.jsonvalue);
        if (jsonValue.Filter.length > 0) {
          for (let i = 0; i < _templateDesc.items.length; i++) {
            const templateItems = _templateDesc.items[i];

            for (let j = 0; j < templateItems.layout.length; j++) {
              const _layout = templateItems.layout[j];
              responseData = await onProcessLogicReference(
                logic,
                _layout.template,
                _layout.data.value,
                _templateDesc.items
              );
              if (responseData) {
                const permittedValues = responseData.data.map(function (
                  value: any
                ) {
                  let res: any = {};
                  for (let i = 0; i < responseData!.loadTo.length; i++) {
                    const field = responseData!.loadTo[i];
                    res[field["TBColumn"]] = value[field["MSTColumn"]];
                  }
                  return res;
                });
                let resRow: any[] = [];
                if (permittedValues) {
                  for (let i = 0; i < permittedValues.length; i++) {
                    const perValue = permittedValues[i];
                    let colData = new Array(
                      templateDesc?.items[responseData.row].layout[
                        responseData.col
                      ].template.attribute.column.length
                    ).fill({ value: "" });
                    for (const [key, value] of Object.entries(perValue)) {
                      for (
                        let j = 0;
                        j <
                        templateDesc?.items[responseData.row].layout[
                          responseData.col
                        ].template.attribute.column.length;
                        j++
                      ) {
                        const _tableCol =
                          templateDesc?.items[responseData.row].layout[
                            responseData.col
                          ].template.attribute.column[j];

                        if (_tableCol.label === key) {
                          colData[j] = { value };
                        }
                      }
                    }
                    resRow.push(colData);
                  }
                  console.log({
                    memoStatus,
                    rowLength:
                      _templateDesc.items[responseData.row].layout[
                        responseData.col
                      ].data.row,
                  });

                  if (
                    resRow.length > 0 &&
                    (!_templateDesc.items[responseData.row].layout[
                      responseData.col
                    ].data.row ||
                      _templateDesc.items[responseData.row].layout[
                        responseData.col
                      ].data?.row?.length === 0)
                  ) {
                    _templateDesc.items[responseData.row].layout[
                      responseData.col
                    ].data.row = [...resRow];
                  }
                }
              }
              console.log({ responseData });
            }
          }
        } else {
          responseData = await onProcessLogicReferenceNoFilter(
            logic,
            _templateDesc.items
          );
          if (responseData) {
            const permittedValues = responseData.data.map(function (
              value: any
            ) {
              let res: any = {};
              for (let i = 0; i < responseData!.loadTo.length; i++) {
                const field = responseData!.loadTo[i];
                res[field["TBColumn"]] = value[field["MSTColumn"]];
              }
              return res;
            });
            let resRow: any[] = [];
            if (permittedValues) {
              for (let i = 0; i < permittedValues.length; i++) {
                const perValue = permittedValues[i];
                let colData = new Array(
                  templateDesc?.items[responseData.row].layout[
                    responseData.col
                  ].template.attribute.column.length
                ).fill({ value: "" });
                for (const [key, value] of Object.entries(perValue)) {
                  for (
                    let j = 0;
                    j <
                    templateDesc?.items[responseData.row].layout[
                      responseData.col
                    ].template.attribute.column.length;
                    j++
                  ) {
                    const _tableCol =
                      templateDesc?.items[responseData.row].layout[
                        responseData.col
                      ].template.attribute.column[j];

                    if (_tableCol.label === key) {
                      colData[j] = { value };
                    }
                  }
                }
                resRow.push(colData);
              }
              console.log({
                memoStatus,
                rowLength:
                  _templateDesc.items[responseData.row].layout[responseData.col]
                    .data.row,
              });

              if (
                resRow.length > 0 &&
                (!_templateDesc.items[responseData.row].layout[responseData.col]
                  .data.row ||
                  _templateDesc.items[responseData.row].layout[responseData.col]
                    .data?.row?.length === 0)
              ) {
                _templateDesc.items[responseData.row].layout[
                  responseData.col
                ].data.row = [...resRow];
              }
            }
          }
        }
      } else if (logic.logictype === "datareladtoloaddata") {
        const jsonValue: ILogicTypeReladToLoadData =
          logic.jsonvalue &&
          logic.jsonvalue.length > 0 &&
          JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));

        let newTableOptions: {
          loadtoLabel: string;
          options: any[];
        } = { loadtoLabel: jsonValue.autoloadvaluelabel.label, options: [] };

        // const { dataJson, loadTo, actionFrom }  = await onFindDataJson(_templateDesc, jsonValue,logic);
        let rowIdx = 0;
        const conditions = jsonValue.conditions;

        conditions.forEach((e: any) => {
          for (let i = 0; i < _templateDesc.items.length; i++) {
            const templateItems = _templateDesc.items[i];
            for (let j = 0; j < templateItems.layout.length; j++) {
              const _layout = templateItems.layout[j];
              if (_layout.template.type === "tb") {
                for (
                  let l = 0;
                  l < _layout.template.attribute.column.length;
                  l++
                ) {
                  const tbCol = _layout.template.attribute.column[l];
                  if (tbCol.control.template.label === e.label) {
                    if (_layout.data.row) {
                      rowIdx = _layout.data.row.length;
                    }
                  }
                }
              }
            }
          }
        });
        for (let x = 0; x < rowIdx; x++) {
          const responseData = await onProcessLogicDataRelatedToLoadData(
            _templateDesc,
            logic,
            jsonValue,
            x
          );
          console.log("newTableOptions=>", responseData);
          if (responseData) {
            if (responseData.data) {
              for (let i = 0; i < _templateDesc.items.length; i++) {
                const templateItems = _templateDesc.items[i];
                for (let j = 0; j < templateItems.layout.length; j++) {
                  const _layout = templateItems.layout[j];

                  if (_layout.template.type === "tb") {
                    for (
                      let l = 0;
                      l < _layout.template.attribute.column.length;
                      l++
                    ) {
                      const tbCol = _layout.template.attribute.column[l];
                      if (
                        tbCol.control.template.label ===
                        newTableOptions.loadtoLabel
                      ) {
                        if (_layout.data) {
                          if (
                            tbCol.control.template.label ===
                            newTableOptions.loadtoLabel
                          ) {
                            //const rawData: any = await onDatareladToLoadData(dataJson);
                            if (_layout.data.row) {
                              for (
                                let m = 0;
                                m < _layout.data.row.length;
                                m++
                              ) {
                                // newTableOptions.options = new Array(
                                //   _layout.data.row?.length
                                // ).fill(responseData);
                              }
                              newTableOptions.options.push({
                                actionsCol: {
                                  label: jsonValue.labelactions,
                                  rowIdx: responseData.actionFrom.row,
                                  colIdx: responseData.actionFrom.col,
                                  value:
                                    templateItems[tbCol.control.template.label],
                                },
                                targetCol: {
                                  label: newTableOptions.loadtoLabel,
                                  rowIdx: responseData.loadTo.row,
                                  colIdx: responseData.loadTo.col,
                                  options: responseData.data.data,
                                },
                              });
                              console.log("newTableOptions=>", newTableOptions);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        if (newTableOptions.options.length > 0) {
          setTableOptions([...newTableOptions.options]);
        }
      } else if (logic.logictype === "refinfomation") {
        const jsonValue: ILogicReferenceInformation =
          logic.jsonvalue &&
          logic.jsonvalue.length > 0 &&
          JSON.parse(logic.jsonvalue);
        console.log("refinfomation=>", jsonValue);
        if (memoStatus === "New Request") {
          for (let i = 0; i < _templateDesc.items.length; i++) {
            const templateItems = _templateDesc.items[i];
            for (let j = 0; j < templateItems.layout.length; j++) {
              const _layout = templateItems.layout[j];
              if (_layout.template.type !== "tb") {
                if (_layout.template.label === jsonValue.condition) {
                  console.log("refinfomation=> requestor", requestor);
                  if (jsonValue.required === "Requestor_Employee_code") {
                    _layout.data.value = requestor.EmployeeCode;
                  } else if (jsonValue.required === "Requestor_Name") {
                    if (requestor.DefaultLang === "EN") {
                      _layout.data.value = requestor.NameEn;
                    } else {
                      _layout.data.value = requestor.NameTh;
                    }
                  } else if (jsonValue.required === "Requestor_Name") {
                    if (requestor.DefaultLang === "EN") {
                      _layout.data.value = requestor.NameEn;
                    } else {
                      _layout.data.value = requestor.NameTh;
                    }
                  } else if (jsonValue.required === "Requestor_Positon") {
                    if (requestor.DefaultLang === "EN") {
                      _layout.data.value = requestor.PositionNameEn;
                    } else {
                      _layout.data.value = requestor.PositionNameTh;
                    }
                  } else if (jsonValue.required === "Requestor_Division") {
                    if (requestor.DefaultLang === "EN") {
                      _layout.data.value = requestor.DivisionNameEn;
                    } else {
                      _layout.data.value = requestor.DivisionNameTh;
                    }
                  } else if (jsonValue.required === "Requestor_Department") {
                    if (requestor.DefaultLang === "EN") {
                      _layout.data.value = requestor.DepartmentNameEn;
                    } else {
                      _layout.data.value = requestor.DepartmentNameTh;
                    }
                  } else if (jsonValue.required === "Requestor_Email") {
                    _layout.data.value = requestor.Email;
                  }
                }
              }
            }
          }
        }
      } else if (logic.logictype === "datasourcerelated") {
        const logicRelated = logic;
        const jsonValue: any =
          logic.jsonvalue &&
          logic.jsonvalue.length > 0 &&
          JSON.parse(logic.jsonvalue);
        let dataValue;
        for (let i = 0; i < _templateDesc.items.length; i++) {
          const templateItems = _templateDesc.items[i];
          for (let j = 0; j < templateItems.layout.length; j++) {
            const _layout = templateItems.layout[j];

            if (_layout.template.label === jsonValue.label) {
              dataValue = _layout.data.value;
            }
          }
        }
        const dataJson = {
          Key: jsonValue.label,
          Value: dataValue,
          logicid: logicRelated.logicid,
        };
        const responseData = await onProcessLogicDataSourceRelated(
          dataJson,
          jsonValue,
          templateDesc
        );

        if (responseData) {
          let newControl = _templateDesc.items;

          for (let i = 0; i < responseData.relateGroup.length; i++) {
            const relateGroup = responseData.relateGroup[i];
            for (let j = 0; j < responseData.data.length; j++) {
              const relateData = responseData.data[j];
              newControl[relateGroup.row].layout[relateGroup.col].data.value =
                relateData[relateGroup.relateValue.value];
            }
          }
        }
      }
    }
    if (dataSourceLoadGroup.length > 0) {
      dataGroup.push({
        type: "datasourceload",
        dataGroup: dataSourceLoadGroup,
      });
    }
    console.log({ dataGroup });

    const initialLogicData: any[] = await getInitialLogicData(dataGroup);

    for (let i = 0; i < initialLogicData.length; i++) {
      const logicData = initialLogicData[i];

      const processData = formatKeyLogicData(logicData);
      if (logicData.isInTable === false || logicData.isInTable === undefined) {
        _templateDesc.items[processData.row].layout[
          processData.col
        ].template.attribute.items = processData.data;
      } else {
        _templateDesc.items[processData.row].layout[
          processData.col
        ].template.attribute.column[
          processData.tableColumn
        ].control.template.attribute.items = processData.data;
      }
    }

    console.log("logic=>_templateDesc", _templateDesc);

    return _templateDesc;
  };
  const onDataSourceLoadRelated = async (dataJson: any) => {
    const dataSource = await fetch(
      "api/TemplateList/TemplateByid/LoadLogic/GetLoadDataFormControl",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataJson),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return dataSource;
  };
  const onProcessLogicDataSourceRelated = async (
    dataJson: any,
    jsonValue: any,
    _templateDesc: any
  ) => {
    let responseDataSourceLoadRelated: any = {
      relateGroup: [],
      data: [],
    };
    responseDataSourceLoadRelated.data = await onDataSourceLoadRelated(
      dataJson
    );
    if (responseDataSourceLoadRelated.data.length > 0) {
      for (let i = 0; i < jsonValue.relatedvalue?.length; i++) {
        const relateValue = jsonValue?.relatedvalue[i];
        for (let j = 0; j < _templateDesc?.items?.length; j++) {
          const item = _templateDesc?.items[j];
          for (let k = 0; k < item.layout?.length; k++) {
            const layout = item?.layout[k];
            if (layout.template.label === relateValue.label) {
              responseDataSourceLoadRelated.relateGroup.push({
                row: j,
                col: k,
                relateValue,
              });
            }
          }
        }
      }
    }
    return responseDataSourceLoadRelated;
  };
  const onProcessLogicDataRelatedToLoadData = async (
    _templateDesc: any,
    logic: ILogic,
    jsonValue: any,
    rowIdx: number
  ) => {
    const { dataJson, loadTo, actionFrom } = await onFindDataJson(
      _templateDesc,
      jsonValue,
      logic,
      rowIdx
    );

    console.log("logic=>response", dataJson, loadTo, actionFrom);
    if (dataJson) {
      if (dataJson.length > 0) {
        if (isFirstRun.current || checkIfLogicsHaveValue(dataJson)) {
          const rawData: any = await onDatareladToLoadData(dataJson);

          if (rawData && loadTo) {
            let data = formatKeyLogicData({ data: rawData });

            return { data, loadTo, actionFrom };
          }
        }
      }
    }
    return false;
  };

  const checkIfLogicsHaveValue = (dataJson: any[]) => {
    let _bool = false;
    dataJson.forEach((data) => {
      Object.keys(data).map((key) => {
        if (key.toLowerCase() === "value") {
          _bool = true;
        }
      });
    });

    return _bool;
  };

  const onFindDataJson = (
    templateDesc: any,
    jsonValue: ILogicTypeReladToLoadData,
    logic: any,
    rowIdx: number
  ) => {
    const response: {
      dataJson: any[];
      loadTo: { row: number; col: number };
      actionFrom: { row: number; col: number };
    } = {
      dataJson: [],
      loadTo: { row: -1, col: -1 },
      actionFrom: { row: -1, col: -1 },
    };
    const conditions = jsonValue.conditions;
    const _templateDesc = templateDesc;
    console.log("conditions", conditions);

    conditions.forEach((e) => {
      for (let i = 0; i < _templateDesc.items.length; i++) {
        const templateItems = _templateDesc.items[i];
        for (let j = 0; j < templateItems.layout.length; j++) {
          const _layout = templateItems.layout[j];
          if (_layout.template.type === "tb") {
            for (let l = 0; l < _layout.template.attribute.column.length; l++) {
              const tbCol = _layout.template.attribute.column[l];
              if (tbCol.control.template.label === e.label) {
                if (_layout.data.row) {
                  console.log("_dataJson=>", _layout.data.row[rowIdx][l].value);
                  response.dataJson.push({
                    Key: tbCol.control.template.label,
                    Value: _layout.data.row[rowIdx][l].value,
                    logicid: logic.logicid,
                  });
                }
              }
              if (
                jsonValue.autoloadvaluelabel.label ===
                tbCol.control.template.label
              ) {
                response.loadTo = {
                  col: l,
                  row: rowIdx,
                };
              }
            }
          } else {
            if (_layout.template.label === e.label) {
              response.dataJson.push({
                Key: e.label,
                Value: _layout.data.value,
                logicid: logic.logicid,
              });
            }
          }
          response.actionFrom = { row: j, col: i };
        }
      }
    });

    console.log("logic=>response", response);

    return response;
  };

  const getInitialLogicData = async (dataGroup: any) => {
    let dataSourceLoad: any = [];
    const dataSourceLoadGroup: any[] = dataGroup.filter(
      (group: any) => group.type === "datasourceload"
    );
    console.log({ dataSourceLoadGroup });

    if (dataSourceLoadGroup.length > 0) {
      dataSourceLoad = await onDataSourceLoad(dataSourceLoadGroup);
    }
    return [...dataSourceLoad];
  };

  const onDatareladToLoadData = async (dataJson: any) => {
    const response = await fetch(
      "api/TemplateList/TemplateByid/LoadLogic/GetLoadDataFormControl2",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataJson),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(() => false);
    return response;
  };

  function onCheckUserRolePermissionInLogic(
    userRoles: IRolePermission[],
    accessRoles: {
      id: number;
    }[]
  ) {
    for (let i = 0; i < userRoles.length; i++) {
      const userRole = userRoles[i];
      for (let j = 0; j < accessRoles.length; j++) {
        const accessRole = accessRoles[j];
        if (userRole.RoleId === Number(accessRole.id)) {
          return true;
        }
      }
    }
    return false;
  }
  const onFilterLoadtoField = (label: string, templateDesc: any) => {
    for (let i = 0; i < templateDesc.length; i++) {
      const item = templateDesc[i];
      for (let j = 0; j < item.layout.length; j++) {
        const layout = item.layout[j];
        if (layout.template.label === label) {
          return { row: i, col: j };
        }
      }
    }
  };
  const onFilterRefFilterField = (
    refFilter: ILogicReferenceField[],
    currentFieldValue: any
  ) => {
    let _refFilter: ILogicReferenceField[] = [];

    for (let i = 0; i < currentFieldValue.length; i++) {
      const item = currentFieldValue[i];
      for (let j = 0; j < item.layout.length; j++) {
        const layout = item.layout[j];
        for (let i = 0; i < refFilter.length; i++) {
          const _ref = refFilter[i];
          if (_ref.TBColumn === layout.template.label) {
            _refFilter.push({
              MSTColumn: _ref.MSTColumn,
              TBColumn: layout.data.value,
            });
          }
        }
      }
    }
    return _refFilter;
  };
  const onLoadReferenceData = async (dataJson: {
    jsonValue: ILogicTypeReference;
    refFilter: ILogicReferenceField[];
  }) => {
    let orCondition = "";
    const refFilterValueEqualComlumAll = dataJson.refFilter.filter(
      (_ref) => _ref.MSTColumn === dataJson.jsonValue.ColumnAll
    );
    if (refFilterValueEqualComlumAll.length > 0) {
      for (let i = 0; i < refFilterValueEqualComlumAll.length; i++) {
        const ref = refFilterValueEqualComlumAll[i];
        orCondition = `(${ref.MSTColumn}='${ref.TBColumn}' OR ${dataJson.jsonValue.ColumnAll}='All')`;
      }
    }

    let condition = "";

    let refFilterValue: ILogicReferenceField[] = dataJson.refFilter.filter(
      (_ref) => _ref.MSTColumn !== dataJson.jsonValue.ColumnAll
    );

    for (let i = 0; i < refFilterValue.length; i++) {
      const ref = refFilterValue[i];
      if (i !== refFilterValue.length - 1) {
        condition = condition + ref.MSTColumn + "='" + ref.TBColumn + "' AND ";
      } else {
        condition = condition + ref.MSTColumn + "='" + ref.TBColumn + "'";
      }
    }
    if (refFilterValue.length === 0) {
      condition = "1 = 1";
    }

    const _dataJson = {
      ...dataJson.jsonValue,
      orCondition,
      condition,
    };
    console.log({
      dataJson,
      refFilterValue,
      orCondition,
      condition,
      refFilterValueEqualComlumAll,
    });

    const dataSource = await fetch(
      "api/TemplateList/TemplateByid/LoadLogic/GetLoadDataFormControlRef",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(_dataJson),
      }
    )
      .then((response) => response.json())
      .then((data: any) => {
        return data;
      });
    return dataSource;
  };
  const onProcessLogicReference = async (
    logic: ILogic,
    controlTemplate: any,
    controlValue: any,
    control: any
  ) => {
    let responseDataReference: {
      loadTo: any[];
      data: any[];
      row: number;
      col: number;
    } | null = {
      loadTo: [],
      data: [],
      row: -1,
      col: -1,
    };
    const jsonValue: ILogicTypeReference =
      logic.jsonvalue &&
      logic.jsonvalue.length > 0 &&
      JSON.parse(logic.jsonvalue);
    console.log({ control, jsonValue, controlTemplate });

    const loadTo = onFilterLoadtoField(jsonValue.label, control);
    const refFilter = onFilterRefFilterField(jsonValue.Filter, control);

    if (
      refFilter.length > 0 &&
      loadTo &&
      jsonValue.Filter.filter((_ref) => _ref.TBColumn === controlTemplate.label)
        .length > 0
    ) {
      const dataJson = {
        jsonValue,
        refFilter,
      };
      const refData: { DT: any[] } = await onLoadReferenceData(dataJson);
      responseDataReference.data = refData.DT;
      responseDataReference.col = loadTo.col;
      responseDataReference.row = loadTo.row;
      responseDataReference.loadTo = jsonValue.Column;
      return responseDataReference;
    }
    return null;
  };

  const onProcessLogicReferenceNoFilter = async (
    logic: ILogic,
    control: any
  ) => {
    let responseDataReference: {
      loadTo: any[];
      data: any[];
      row: number;
      col: number;
    } | null = {
      loadTo: [],
      data: [],
      row: -1,
      col: -1,
    };
    const jsonValue: ILogicTypeReference =
      logic.jsonvalue &&
      logic.jsonvalue.length > 0 &&
      JSON.parse(logic.jsonvalue);

    const loadTo = onFilterLoadtoField(jsonValue.label, control);
    const refFil: any = jsonValue.Filter;

    if (loadTo && jsonValue.Filter.length === 0) {
      const dataJson = {
        jsonValue,
        refFilter: refFil,
      };
      const refData: { DT: any[] } = await onLoadReferenceData(dataJson);
      responseDataReference.data = refData.DT;
      responseDataReference.col = loadTo.col;
      responseDataReference.row = loadTo.row;
      responseDataReference.loadTo = jsonValue.Column;
      return responseDataReference;
    }
    return null;
  };

  const onControlChange = async (
    controlName: any,
    controlValue: any,
    controlUpdate: any
  ) => {
    if (controlValue === "FA | Financial and Accounting") {
      const _controlString = JSON.stringify(getValues("items"));
      let _control = JSON.parse(_controlString);
      _control[1].layout[1].data.value = "100";
      controlUpdate(1, {
        ..._control[1],
        layout: [..._control[1].layout],
      });
      console.log({ controlName, controlValue, _control });
    }
  };
  const onDataSourceLoad = async (dataGroup: any) => {
    let responseGroup: any[] = [];

    for (let i = 0; i < dataGroup.length; i++) {
      const dataSource: any = dataGroup[i].dataGroup;
      let dataJson: any[] = [];

      for (let j = 0; j < dataSource.length; j++) {
        let jsonValue: any = JSON.parse(dataSource[j].jsonValue);

        await fetch(
          "api/TemplateList/TemplateByid/LoadLogic/GetLoadDataFormControl",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            // test json
            body: JSON.stringify({
              logicid: dataSource[j].data,
              Key: jsonValue.SQLCommand || jsonValue ? "Requestor id" : "",
              Value:
                jsonValue.SQLCommand || jsonValue
                  ? memoDetail?.requestor === undefined
                    ? userData.EmployeeId.toString()
                    : memoDetail?.requestor.EmployeeId.toString()
                  : "",
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("logic=>data", data);

            if (!dataSource[j].isInTable) {
              responseGroup.push({
                isInTable: dataSource.isInTable,
                data: data,
                row: dataSource[j].row,
                col: dataSource[j].col,
              });
            } else {
              responseGroup.push({
                isInTable: dataSource[j].isInTable,
                tableColumn: dataSource[j].tableColumn,
                data: data,
                row: dataSource[j].row,
                col: dataSource[j].col,
              });
            }
          });
      }
      console.log("logic=>dataJson", dataJson);
    }
    console.log("logic=>responseGroup", responseGroup);

    return responseGroup;
  };
  useEffect(() => {
    setTimeout(() => {
      if (!isEmptyObject(requestor)) {
        checkedLeaveTemplateLogic(requestor);
      }

      if (memoDetail?.memoid === 0) {
        if (memoDetail?.checkChangeComp !== true) {
          if (
            requestor.CompanyNameTh === "" ||
            requestor.CompanyNameEn === ""
          ) {
            if (masterCompanies.length === 1) {
              let com_name = masterCompanies[0].CompanyCode + " : ";
              if (userData.Lang === "EN") {
                com_name = com_name + masterCompanies[0].NameEn;
              } else {
                com_name = com_name + masterCompanies[0].NameTh;
              }
              memoDetail.company_name = com_name;
              memoDetail.company_id = masterCompanies[0].CompanyId;
            }
          } else {
            if (userData.Lang === "EN") {
              memoDetail.company_name = requestor.CompanyNameEn;
            } else {
              memoDetail.company_name = requestor.CompanyNameTh;
            }
            memoDetail.company_id = requestor.CompanyId;
          }
        }
      }
    });
  }, [requestor]);
  async function checkedLeaveTemplateLogic(_requestor: any) {
    if (checkedLeaveTemplate) {
      var _responeData = await GetLeaveTemplateByEmpId(_requestor.EmployeeId);
      console.log(_responeData, "_responeData");

      setLeaveTypeTable(_responeData);
    }
    const temp = await prepareInitialLogic(
      allLogic,
      memoDetail.template_desc,
      memoDetail.statusm,
      memoDetail.requestor
    );
    console.log("request=>temp", temp);
    memoDetail.template_desc = temp;
    setMemoDetail(memoDetail);

    // for MBK SITE only
    if (mbkOnlyU !== null && mbkOnlyU.length > 0) {
      console.log("xxxxxx", mbkOnlyU[0].Value1);
      if (mbkOnlyU[0].Value1 === "True") {
        console.log("reset", mbkOnlyU);
        reset(temp);
      }
    }
  }

  //process datalineapprove logic
  const onProcessLogicLineApprove = async (
    logic: ILogic,
    controlTemplate: any,
    controlValue: any,
    control: any
  ) => {
    const jsonValue: ILogicTypeDataLineApprove =
      logic.jsonvalue &&
      logic.jsonvalue.length > 0 &&
      JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));

    const conditionsValue = onFilterCondotionsValue(
      jsonValue,
      control,
      controlTemplate,
      controlValue
    );

    if (conditionsValue.length > 0) {
      return JSON.stringify({
        logicid: logic.logicid,
        conditions: conditionsValue,
      });
    }
    return false;
  };

  const onFilterCondotionsValue = (
    jsonValue: ILogicTypeDataLineApprove,
    _control: any,
    controlTemplate: any,
    controlValue: any
  ) => {
    let conditions: { label: string; value: string; action?: string }[] = [];
    for (let i = 0; i < jsonValue.Conditions.length; i++) {
      const condition = jsonValue.Conditions[i];

      if (condition.label === controlTemplate.label) {
        conditions.push({
          label: condition.label,
          value: controlValue ? controlValue.toLocaleString() : "0",
          action: condition.action,
        });
      } else {
        for (let j = 0; j < _control.length; j++) {
          const item = _control[j];

          for (let k = 0; k < item.layout.length; k++) {
            const layout = item.layout[k];

            if (
              condition.label === layout.template.label &&
              layout.template.label !== controlTemplate.label
            ) {
              conditions.push({
                label: condition.label,
                value: layout.data.value
                  ? layout.data.value.toLocaleString()
                  : "0",
                action: condition.action,
              });
            }
          }
        }
      }
    }
    return conditions;
  };

  async function renderSelectedRequestor(_requestor: any) {
    try {
      console.log(checkActionPage);
      // initial save JsonCondition(logictype="datalineapprove") when change requestor
      // when change requestor the current level must be 0
      setMemoDetail((prevState: any) => ({
        ...prevState,
        current_approval_level: 0,
      }));

      if (checkActionPage) {
        if (memoDetail) {
          let allTemplateControl: any;
          if (memoDetail.template_desc.items) {
            allTemplateControl = memoDetail.template_desc.items.map(
              (item: any) => {
                return item;
              }
            );
          } else {
            allTemplateControl = "";
          }

          const logicDataLineApprove = allLogic.find((item: any) => {
            return item.logictype === "datalineapprove";
          });

          if (logicDataLineApprove) {
            const logiceiei = JSON.parse(logicDataLineApprove.jsonvalue);
            memoDetail.template_desc.items.map(async (detail: any) => {
              const desc = detail.layout.find(
                (item: any) => item.template.label === logiceiei.label
              );
              if (desc) {
                const responseData = await onProcessLogicLineApprove(
                  logicDataLineApprove,
                  desc,
                  desc.data.value,
                  allTemplateControl
                );

                getLineApproveForAmount(
                  Number(memoDetail.amount.replaceAll(",", "")),
                  responseData,
                  "requestor",
                  undefined,
                  _requestor
                );
              }
            });
          } else {
            getLineApproveForAmount(
              Number(memoDetail.amount.replaceAll(",", "")),
              undefined,
              "requestor",
              undefined,
              _requestor
            );
          }
        }
      }
    } catch (error) {
      console.error("renderSelectedRequestor=>error", error);
    }
  }

  async function checkTemplateVersion(listFormName: any, memoDetail: any) {
    if (listFormName.IsFormControl) {
      const _templateApproveId = Number(memoDetail.TemplateApproveId);
      let _listTemplate = await SearchTemplateListEditing({
        TemplateId: null,
        CreatedBy: userData.EmployeeId.toString(),
      });
      let _dataVersionTempVC = await getVersionTempVC();
      let arrayEditingVersion = [];
      let arrayEditing = [];
      for (let i = 0; i < _listTemplate.length; i++) {
        const element = _listTemplate[i];
        for (let j = 0; j < _dataVersionTempVC.length; j++) {
          const _element = _dataVersionTempVC[j];
          if (_element.value1 === element.DocumentCode) {
            if (_element.value3 === "Editing")
              arrayEditingVersion.push(element);
          }
        }
      }

      setTemplateListVersion(arrayEditingVersion);
      let _responseTemplate: any;
      if (memoDetail.TemplateApproveId) {
        _responseTemplate = await SearchTemplateListEditing({
          TemplateId: _templateApproveId,
        });
        if (_responseTemplate) {
          _responseTemplate = await GetTemplateById({
            TemplateId: _templateApproveId,
          });
        }
        console.log("_responseTemplate", _responseTemplate);

        if (_responseTemplate[0]) {
          setSelectedTemplateVersion(_responseTemplate[0]);
        } else {
          setSelectedTemplateVersion(_responseTemplate);
        }
      }
      setIsTemplateVersion(true);
    }
  }

  const checkLeaveRequestTemplate = async (template_code: string) => {
    var _lRTempCode = lRTempCode;
    if (_lRTempCode) {
      for (let index = 0; index < _lRTempCode.length; index++) {
        if (_lRTempCode[index].Value1 === template_code) {
          setCheckedLeaveTemplate(true);
        } else {
          setCheckedLeaveTemplate(false);
        }
      }
    }
  };
  const replaceTitleInfo = async (memoDetail: IMemoDetailModel) => {
    var _responeADTitle = await ADTitleConfiguration();
    if (_responeADTitle?.activeBranchFromADTitle?.toLowerCase() === "true") {
      setIsBranchFromADTitle(true);
    }
    if (_responeADTitle?.activeADTitleToPosition?.toLowerCase() === "false") {
      if (memoDetail?.requestor?.ADTitle) {
        setIsADTitleToPosition(true);
      }
    }
  };
  const checkDataAndSetFunction = async (memoDetail: IMemoDetailModel) => {
    if (!memoDetail.template_detail || !memoDetail.document_set) {
      const _guid = generateQuickGuid();
      memoDetail.template_detail = _guid;
      memoDetail.document_set = _guid;
    }

    if (memoDetail.memoid === 0) {
      if (userData.CompanyNameTh === "" || userData.CompanyNameEn === "") {
        if (masterCompanies.length === 1) {
          let com_name = masterCompanies[0].CompanyCode + " : ";
          if (userData.Lang === "EN") {
            com_name = com_name + masterCompanies[0].NameEn;
          } else {
            com_name = com_name + masterCompanies[0].NameTh;
          }
          memoDetail.company_name = com_name;
          memoDetail.company_id = masterCompanies[0].CompanyId;
        }
      } else {
        if (userData.Lang === "EN") {
          memoDetail.company_name = userData.CompanyNameEn;
        } else {
          memoDetail.company_name = userData.CompanyNameTh;
        }
        memoDetail.company_id = userData.CompanyId;
      }
    }

    if (memoDetail.project_id !== 0) {
      masterProjects.map((_item: any) => {
        if (memoDetail.project_id === _item.ProjectId) {
          setProject(_item);
        }
      });
    }
    if (memoDetail.to) {
      const _spited = memoDetail.to.split(",");
      setListInToAndPass((prevState: any) => ({
        ...prevState,
        to: _spited,
      }));
    }
    if (memoDetail.pass) {
      const _spited = memoDetail.pass.split(",");
      setListInToAndPass((prevState: any) => ({
        ...prevState,
        pass: _spited,
      }));
    }
  };
  const checkState = (
    memoStatus: string,
    listFormName: any,
    memoButtons: any[]
  ) => {
    if (
      memoStatus === "Draft" ||
      memoStatus === "New Request" ||
      memoStatus === "Rework" ||
      memoStatus === "Recall"
    ) {
      setCanEditDoc(true);
      setCanEditRefDoc(true);
      if (listFormName.IsRequesterEditApproval) {
        setCanEditLineApprove(listFormName.IsRequesterEditApproval);
      }
    } else if (
      memoStatus === "Completed" ||
      memoStatus === "Rejected" ||
      memoStatus === "Cancelled"
    ) {
      console.log({ memoButtons });
      if (memoButtons.length > 0) {
        setCanEditDoc(true);
      }
      setCanEditLineApprove(false);
    } else {
      if (listFormName.ApproverCanEdit) {
        setCanEditDoc(true);
        if (listFormName.IsRequesterEditApproval) {
          setCanEditLineApprove(listFormName.IsRequesterEditApproval);
        }
      } else {
        setCanEditDoc(false);
      }
      if (listFormName.IsCheckAccess) {
        setIsCheckAccess(true);
      }
    }
  };
  async function checkRefTemplate(
    template: any,
    listRefDocDetails: any,
    refDocs: any,
    _RefID: string
  ) {
    const temp = template;
    try {
      if (temp) {
        if (temp?.RefDocDisplay && temp.RefTemplate) {
          let _refDoc: any[] = [];
          const display = temp?.RefDocDisplay.split(",");
          const _refTemplate = JSON.parse(temp.RefTemplate);
          if (display[2] !== "Information DocumentNo") {
            refDocs.map((ref: any) => {
              if (ref !== null) {
                if (display[2] !== "Information DocumentNo") {
                  ref[display[2]] = ref.SelectField.value;
                  if (typeof ref[display[2]] === "string") {
                    _refDoc.push(ref);
                  }
                }
              }
            });
          } else {
            _refDoc = refDocs;
          }

          let refObject: any = {
            option: [],
            position: display[0],
            mode: display[1],
            optionLabel:
              display[2] !== "Information DocumentNo"
                ? display[2]
                : "DocumentNo",
            isDefaultLineApprove: _refTemplate[0].IsDefaultLineApprove,
            refIdOnQuery: null,
          };
          setRefOptions([..._refDoc.filter((e: any) => e)]);
          if (_RefID !== "") {
            const results = _refDoc.filter(
              (refDoc: any) => refDoc.DocumentNo === _RefID
            );
            refObject.refIdOnQuery = _RefID;
            previousRefTempSelected.current = results;
            setRefTempSelected(results);
          } else if (listRefDocDetails?.length > 0) {
            const refDocsDetail: any[] = listRefDocDetails;
            let results = refDocs.filter(
              (refDoc: any) =>
                refDocsDetail.find(
                  (docDetail: any) => docDetail.memoRefdoc_id === refDoc.MemoId
                ) || refDoc.DocumentNo === _RefID
            );
            if (results.length === 0) {
              let dataJson: any[] = [];
              listRefDocDetails.forEach((e: any) => {
                userData.SignPicPath = "";
                dataJson.push({
                  memoid: e.memoRefdoc_id,
                  EmployeeId: userData.EmployeeId.toString(),
                  actor: userData,
                });
              });
              results = await GetMemoDetailOnlyById(dataJson);
              if (display[2] !== "Information DocumentNo") {
                results = results.map((ref: any) => {
                  if (ref !== null) {
                    if (display[2] !== "Information DocumentNo") {
                      let refTemp = JSON.parse(ref.Template_desc);
                      refTemp.items.forEach((col: any, rowIdx: number) => {
                        col.layout.forEach((_layout: any, colIdx: number) => {
                          if (_layout.template.label === display[2]) {
                            if (_layout.template.type === "c") {
                              ref[display[2]] = Number(
                                _layout.data.value
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: Number(
                                  _layout.template.attribute.decimal
                                ),
                              });
                            } else {
                              if (_layout.data.value) {
                                ref[display[2]] = _layout.data.value;
                              } else {
                                ref[display[2]] = "";
                              }
                            }
                          }
                        });
                      });
                      console.log("results", results, ref, refDocsDetail);
                      if (typeof ref[display[2]] === "string") {
                        let res: any = {
                          MemoId: ref.Memoid,
                          เลขที่เอกสาร: ref.doc_no,
                          TemplateName: ref.Template_name,
                          MemoSubject: ref.Subject,
                        };
                        res[display[2]] = ref[display[2]];
                        console.log("res", res);
                        return res;
                      }
                    }
                  }
                });
              }
            }

            previousRefTempSelected.current = results;
            setRefTempSelected(results);
          }
          setRefAttribute({ ...refObject });
          setSearchRefDocData([..._refDoc]);
        } else {
          setRefTempSelected([]);
          setRefAttribute(undefined);
        }
      }
    } catch (error) {
      console.log("ref=>error", error);
    }
  }

  const print = () => {
    // console.log("---------------------MemoPage Value-----------------------");
    console.log("memoDetail=>", memoDetail);
    // console.log("checkActionPage=>", checkActionPage);
    // console.log({ errors });
    // console.log("lineApproval=>", lineApproval);
    console.log("listFormNames=>", listFormNames);
    // console.log("listHistoryDetails=>", listHistoryDetails);
    // console.log("listFileAttachDetails=>", listFileAttachDetails);
    // console.log("listRefDocDetails=>", listRefDocDetails);
    // console.log("---------------------RefDoc Attibute-----------------------");
    console.log("refAttibute=>", refAttribute);
    console.log("refTempSelected=>", refTempSelected);
    console.log("searchRefDocData=>", searchRefDocData);

    // console.log("---------------------MasterData-----------------------");
    // console.log("masterSignature=>", masterSignature);
    // console.log("validField", masterDataValidField);
    // console.log("masteProjects=>", masteProjects);
    // console.log("masteEmployee=>", masteEmployee);
    // console.log("masteCompanies=>", masteCompanies);
    // console.log("searchTemplateListEditing=>", searchTemplateListEditing);
    // console.log("menuButton=>", menuButton);
    console.log("allLogic=>", allLogic);
    // console.log("finFo=>", finFo);
    // console.log("listRefDocs=>", listRefDocs);
    // console.log("---------------------Can Edit-----------------------");
    // console.log("canEditDoc=>", canEditDoc);
    // console.log("table=>tableSummaries", tableSummaries);
    // console.log("autoNumFormat=>", autoNumFormat);
  };
  function dataGetInformationMethodMemoProp(
    listFormName: any,
    memoDetail: IMemoDetailModel
  ) {
    memoDetail.memoid = 0;
    memoDetail.request_date = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    memoDetail.created_date = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    memoDetail.status = "New Request";
    memoDetail.modified_date = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    memoDetail.modified_by = userData.EmployeeId.toString();
    memoDetail.created_by = userData.EmployeeId.toString();
    memoDetail.creator = userData;
    memoDetail.requestor = userData;
    memoDetail.report_lang = listFormName.ReportLang;
    memoDetail.auto_approve = listFormName.AutoApprove;
    memoDetail.auto_approve_when = listFormName.AutoApproveWhen;
    memoDetail.GroupTemplateName = listFormName.GroupTemplateName;
    memoDetail.template_id = listFormName.TemplateId;
    memoDetail.template_name = listFormName.TemplateName;
    memoDetail.template_code = listFormName.DocumentCode;
    memoDetail.document_no = "Auto Generate";
    memoDetail.waiting_for = userData.NameTh;
    memoDetail.waiting_for_id = userData.EmployeeId;
    memoDetail.company_name = "";
    memoDetail.company_id = 0;
    memoDetail.project_id = 0;
    memoDetail.project = "";
    memoDetail.subject = listFormName.TemplateSubject;
    memoDetail.amount = "";
    memoDetail.to = listFormName.ToId;
    memoDetail.pass = listFormName.CcId;
  }

  function toggleSideBar() {
    setSidebarState(!sidebarState);
  }

  const onSelectView = (text: string) => {
    console.log(text, "props.curPage");

    setCurPage(text);
    setSelectedView(text);
  };

  const createTableSums = (templateDesc: any) => {
    let sumIntable: any[] = [];
    templateDesc?.items?.forEach((item: any, rowIdx: number) => {
      item.layout.forEach((layout: any) => {
        if (layout.template.type === "tb") {
          const cols = layout.template.attribute.column;
          let sum: any = {};
          cols.forEach((e: any) => {
            if (e.control.template.type === "c") {
              sum[e.label] = 0;
            }
          });
          sumIntable.push({ tableTemp: layout.template, AllCol: sum });
        }
      });
    });

    setTableSummaries([...sumIntable]);
  };

  function CheckValidField(formData: any) {
    const getCheckValid = [];
    let datepicker1: any;
    let datepicker2: any;
    let datepickerLabel1: any;
    let datepickerLabel2: any;
    if (masterDataValidField) {
      try {
        const ValidField = masterDataValidField[0];
        const masterDataValid = ValidField.Value2.split("|");
        const masterDataLabel1 = masterDataValid[0].toString();
        const masterDataLabel2 = masterDataValid[1].toString();

        setMessageValidField(masterDataValidField[0].Value4);
        formData.items.map((item: any) => {
          item.layout.map((layout: any) => {
            if (layout.template.label === masterDataLabel1) {
              datepicker1 = layout;
              datepickerLabel1 = datepicker1.template.label;
            }
            if (layout.template.label === masterDataLabel2) {
              datepicker2 = layout;
              datepickerLabel2 = datepicker2.template.label;
            }
          });
        });
        if (
          datepickerLabel1 === masterDataLabel1 &&
          datepickerLabel2 === masterDataLabel2
        ) {
          let newDate1 = new Date(datepicker1.data.value);
          let newDate2 = new Date(datepicker2.data.value);
          getCheckValid.push(newDate1, newDate2);
        }
      } catch (error) {
        console.log("masterDataValid=>error", error);
      }
    }
    return getCheckValid;
  }

  const onSubmit = async (formData: any, data: IRequestOnSubmit) => {
    // variables RequestScreen
    console.log(formData, "formData");

    const _submitType = data.buttonType;
    let _memoDetail: IMemoDetailModel = memoDetail;
    let _lineApproval: any[] = lineApproval;
    let dateValidField1: any = CheckValidField(formData)[0];
    let dateValidField2: any = CheckValidField(formData)[1];
    let _listHistoryDetails: any[] = listHistoryDetails;
    let _listFileAttachDetails: any[] = listFileAttachDetails;
    let _listRefDocDetails: any[] = listRefDocDetails;
    let _listControlRunning: any[] = [];
    let _listFormNames = listFormNames;
    let _userData: IUserModel = userData;
    // variables Information
    let _listInToAndPass: any = listInToAndPass;
    let _project: any = project;
    let _tempVersion: any = selectedTemplateVersion;
    let message: any = masterDataValidField
      ? masterDataValidField[0]
        ? masterDataValidField[0].Value4
        : ""
      : "";
    // test validatt
    const dataJson = {
      TemplateId: memoDetail.template_id,
    };
    let _templateIdList: any = await GetTemplateControlById(dataJson);
    const _validation = Validation(
      _submitType,
      memoDetail,
      lineApproval,
      dateValidField1,
      dateValidField2,
      message
    );
    if (
      _templateIdList.templateForm?.RequiredAttachment === true &&
      _listFileAttachDetails.length === 0
    ) {
      toggleAlert({
        description: `Required Attachment`,
        message: `Required Attachment.`,
        type: "warning",
      });
      return;
    }
    setLoad(true);

    if (!isTextFromValue) {
      CheckValidField(formData);
      formData.items.map((item: any) => {
        item.layout.map((layout: any) => {
          if (layout.data.value === null) {
            layout.data.value = layout.template?.attribute?.default;
          }
        });
      });

      const formatFormData = formData?.items?.map((item: any, idx: number) => {
        return {
          ...item,
          layout: item.layout?.map((_layout: any, idx2: number) => {
            if (_layout.template.type === "l") {
              return {
                ..._layout,
                template: {
                  ..._layout.template,
                  ["attribute"]: null,
                },
              };
            } else {
              return _layout;
            }
          }),
        };
      });

      _memoDetail.template_desc = JSON.stringify({ items: formatFormData });
    } else {
      _memoDetail.is_text_form = true;
      _memoDetail.template_desc = textFromValue;
    }

    if (_validation.length >= 1) {
      toggleAlert({
        description: `Please fill ${_validation.join(" , ")}`,
        message: `Require field warning.`,
        type: "warning",
      });
    }

    //set Value
    _memoDetail.comment = data?.inputComment || "";
    _memoDetail.waiting_for = data?.waitingFor || _userData.NameTh;
    _memoDetail.waiting_for_id = data?.waitingForId || _userData.EmployeeId;
    _memoDetail.to = _listInToAndPass.to.join(",");
    _memoDetail.pass = _listInToAndPass.pass.join(",");
    _memoDetail.project_id = _project?.ProjectId || 0;
    _memoDetail.project = _project?.ProjectName || "";
    _memoDetail.TemplateApproveId = _tempVersion?.TemplateId || null;
    _memoDetail.actor = _userData;

    if (
      autoNumFormat.rowIndex !== -1 &&
      _memoDetail.status === "New Request" &&
      _submitType !== "rework" &&
      _submitType !== "recall" &&
      _submitType !== "return" &&
      _submitType !== "reply"
    ) {
      const runningNumber = await CheckSaveAutonumber(formData);
      if (runningNumber) {
        _listControlRunning.push(runningNumber);
      }
    }
    if (_listFormNames.IsCheckAccess) {
      if (_memoDetail?.actorCheckAccess) {
        const checkAccessRequestModel = {
          memoid: _memoDetail.memoid?.toString(),
          RequesterId: null,
        };

        const result = await SetCheckAcces(checkAccessRequestModel);
        if (canEditDoc) {
          setCanEditDoc(false);
        }
      }
    }

    const _requestMemoPage = {
      MemoPage: {
        listApprovalDetails: _lineApproval,
        listFileAttachDetails: _listFileAttachDetails,
        listFormName: [_listFormNames],
        listHistoryDetails: _listHistoryDetails,
        listRefDocDetails: _listRefDocDetails,
        memoDetail: _memoDetail,
        listControlRunning: _listControlRunning,
        UserPrincipalName: userData.Email,
      },
      Type: _submitType,
    };
    console.log("memo=>_requestMemoPage", _requestMemoPage);
    let _respone = await ActionMemoPage(_requestMemoPage);
    console.log("actionAlert=Response=>", _respone);
    if (_respone) {
      setActionAlert(_respone);
    }
    const _checkRespone = ResponeValidation(_respone);

    if (checkRequestActionMemo) {
      history.push("/RequestActionMemo");
    }
    //success
    else if (_checkRespone) {
      if (checkActionPage === "edit" && _submitType === "draft") {
        checkQuery();
        setLoadingPDF(!loadingPDF);
      } else {
        history.push("/Default", {
          responeData: _respone,
          msg: `${_submitType} Success`,
        });
      }
      setLoad(false);
    }
    // something is wrong
    else {
      toggleAlert({
        description: `${_respone.Message}`,
        message: `Submit error.`,
        type: "error",
        duration: 99999999,
      });
    }
    setLoad(false);
  };

  const IsCheckAccessEditing = () => {
    let isCheck = false;
    if (memoDetail?.actorCheckAccess?.EmployeeId === userData.EmployeeId) {
      isCheck = true;
    }
    return isCheck;
  };

  const CheckSaveAutonumber = async (template_desc: any) => {
    try {
      const rowIndex = autoNumFormat.rowIndex;
      const colIndex = autoNumFormat.colIndex;
      if (template_desc) {
        let value =
          template_desc?.items[rowIndex]?.layout[colIndex]?.data?.value;
        const autoTemp = template_desc?.items[rowIndex]?.layout[colIndex];
        if (value) {
          let requestBody = null;
          let newRunning = -1;
          if (autoNumFormat.showSymbol) {
            const dd = value.split("-");
            const gg = dd.slice(0, dd.length - 1);
            requestBody = {
              Prefix: gg.join("-") + "-",
              Digit: autoTemp.template.digit,
              TemplateId: memoDetail.template_id,
              RunningNumber: dd.join("-"),
            };
            const checkAuto = await GetSaveRunningNumber(requestBody);
            const checkNum = checkAuto.split("-");
            if (checkNum[0] !== "") {
              newRunning = checkNum[checkNum.length - 1];
              dd[dd.length - 1] = newRunning;
              requestBody.RunningNumber = gg.join("-") + "-" + newRunning;
            }
          } else {
            requestBody = {
              Prefix: autoNumFormat.fisrtPreix,
              Digit: autoTemp.template.digit,
              TemplateId: memoDetail.template_id,
              RunningNumber: value,
            };
            const checkAuto = await GetSaveRunningNumber(requestBody);
            const checkNum = checkAuto.replaceAll(autoNumFormat.fisrtPreix, "");
            let dd = value.replaceAll(autoNumFormat.fisrtPreix, "");
            if (checkNum !== "") {
              newRunning = checkNum;
              dd = newRunning;
              if (autoNumFormat?.fisrtPreix) {
                requestBody.RunningNumber =
                  autoNumFormat?.fisrtPreix + newRunning;
              }
            }
          }

          return requestBody;
        }
      }
    } catch (error) {
      console.log("auto=>CheckSaveAutonumber=>error", error);
    }
  };

  if (!memoDetail) {
    return <></>;
  }

  return (
    <div className="request-main-container">
      <div className="request-container">
        <Toast ref={toast} />
        <div className="header-request set-z-index">
          <div className="button-container">
            <Button
              className="btn-sidebar-request"
              type="text"
              icon={<IoMenu size={28} />}
              size="large"
              onClick={toggleSideBar}
              style={{ background: "transparent " }}
            />
            <TreeSelectNewRequest
              setDataTemplateTreeProps={setDataTreeSelectProps}
            />
          </div>
          {!isLoad && (
            <div
              className={
                memoDetail?.actorCheckAccess
                  ? IsCheckAccessEditing()
                    ? "confirm-container"
                    : "checkaccess-container"
                  : "confirm-container"
              }
            >
              {checkActionPage === "add" && (
                <div className="memo-button-container">
                  <MemoSingleButton
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    pageName={"Request"}
                    type="submit"
                    buttonType={buttonType}
                    setButtonType={setButtonType}
                    setSelectedView={setSelectedView}
                    setCurPage={setCurPage}
                    lineApproval={lineApproval}
                    approvalSequence={memoDetail.current_approval_level}
                  />
                  <MemoSingleButton
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    pageName={"Request"}
                    type="draft"
                    buttonType={buttonType}
                    setButtonType={setButtonType}
                    setSelectedView={setSelectedView}
                    setCurPage={setCurPage}
                    lineApproval={lineApproval}
                    approvalSequence={memoDetail.current_approval_level}
                  />
                </div>
              )}

              {((checkActionPage === "edit" && !memoDetail?.actorCheckAccess) ||
                IsCheckAccessEditing()) && (
                <MemoButtonComponent
                  setSelectedView={setSelectedView}
                  memoMenu={menuButton}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  pageName={"Request"}
                  buttonType={buttonType}
                  setButtonType={setButtonType}
                  setCurPage={setCurPage}
                  lineApproval={lineApproval}
                  approvalSequence={memoDetail.current_approval_level}
                />
              )}
              {checkActionPage === "edit" && (
                <>
                  {loadingPDF && (
                    <OtherButtonMemoComponent
                      pdfData={location.state?.pdfData}
                      memoIdForCopy={
                        query.get("MemoID") ? query.get("MemoID") : ""
                      }
                      isCheckAcces={isCheckAccess}
                      memoDetail={{
                        listApprovalDetails: lineApproval,
                        listFileAttachDetails: listFileAttachDetails,
                        listFormNames: [listFormNames],
                        listHistoryDetails: listHistoryDetails,
                        listRefDocDetails: listRefDocDetails,

                        memoDetail: {
                          ...memoDetail,
                          template_desc: memoDetail.template_desc
                            ? JSON.stringify(memoDetail.template_desc)
                            : null,
                        },
                      }}
                      canEdit={canEditDoc}
                      setCanEdit={setCanEditDoc}
                      isControlLoading={isControlLoading}
                      setIsControlLoading={setIsControlLoading}
                      permission={permission}
                    />
                  )}
                  {!loadingPDF && (
                    <OtherButtonMemoComponent
                      memoDetail={{
                        listApprovalDetails: lineApproval,
                        listFileAttachDetails: listFileAttachDetails,
                        listFormNames: [listFormNames],
                        listHistoryDetails: listHistoryDetails,
                        listRefDocDetails: listRefDocDetails,
                        memoDetail: {
                          ...memoDetail,
                          template_desc: JSON.stringify(
                            memoDetail?.template_desc
                          ),
                        },
                      }}
                      pdfData={location.state?.pdfData}
                      memoIdForCopy={
                        query.get("MemoID") ? query.get("MemoID") : ""
                      }
                      isCheckAcces={isCheckAccess}
                      canEdit={canEditDoc}
                      setCanEdit={setCanEditDoc}
                      isControlLoading={isControlLoading}
                      setIsControlLoading={setIsControlLoading}
                      permission={permission}
                    />
                  )}
                </>
              )}
            </div>
          )}
          <div></div>
        </div>
        {!isLoad && (
          <>
            {sidebarState ? (
              <div className="show-tablet-navbar-request-min-1024">
                <RequestSideBarElement
                  onSelectView={onSelectView}
                  curPage={curPage}
                  workList={memoDetail?.memoid}
                  requestData={requestDetail}
                />
              </div>
            ) : (
              <div></div>
            )}
            <div className="inner-content">
              <div className="worklist-items-container">
                {sidebarState && (
                  <motion.div
                    className="inner-content show-tablet-navbar-request-max-1024"
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -200 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="filter-container">
                      <RequestSideBarElement
                        onSelectView={onSelectView}
                        curPage={curPage}
                        workList={memoDetail?.memoid}
                        requestData={requestDetail}
                      />
                    </div>
                  </motion.div>
                )}
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    layout
                    className="request-container-item"
                    style={{ width: sidebarState ? "82%" : "100%" }}
                    onClick={() => print()}
                    key={selectedView}
                    initial={{ x: 10, opacity: 0 }}
                    // whileFocus={{transition: {duration: 0}}}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -10, opacity: 0 }}
                    transition={{ duration: duration }}
                  >
                    {selectedView === "1" && (
                      <RequestorComponent
                        userData={userData}
                        renderSelectedRequestor={renderSelectedRequestor}
                        onLoadDataLiveApprove={onLoadDataLiveApprove}
                        lineApproval={lineApproval}
                        jsonCondition={jsonConditions}
                        memoDetail={memoDetail}
                        setMemoDetail={setMemoDetail}
                        canEditDoc={canEditDoc}
                        masterEmployee={masterEmployee}
                        setLineApproval={setLineApproval}
                        isADTitleToPosition={isADTitleToPosition}
                        requestor={requestor}
                        creator={creator}
                        setRequestor={setRequestor}
                        t={t}
                      />
                    )}
                    {selectedView === "2" && (
                      <InformationComponentFix
                        listFormNames={listFormNames}
                        setListRefDocDetails={setListRefDocDetails}
                        numFormulas={numFormulas}
                        canEditRefDoc={canEditRefDoc}
                        refLoading={refLoading}
                        setLineApproval={setLineApproval}
                        setListFileAttachDetails={setListFileAttachDetails}
                        onBlurProps={() => setDuration(0.2)}
                        onFocusProps={() => setDuration(0)}
                        {...{
                          tableOptions,
                          setTableOptions,
                          onProcessLogicReference,
                          refOptions,
                          prepareInitialLogic,
                          isFirstRun,
                          previousRefTempSelected,
                          isShowSum,
                          previousView,
                          buttonType,
                          control,
                          register,
                          handleSubmit,
                          onSubmit,
                          errors,
                          onControlChange,
                          allLogic,
                          reset,
                          memoDetail,
                          jsonConditions,
                          setJsonConditions,
                          setValue,
                          canEditDoc,
                          checkActionPage,
                          isControlLoading,
                          setIsControlLoading,
                          tableSummaries,
                          setTableSummaries,
                        }}
                        memoDetail={memoDetail}
                        setMemoDetail={setMemoDetail}
                        masterCompanies={masterCompanies}
                        masterEmployee={masterEmployee}
                        setListInToAndPass={setListInToAndPass}
                        listInToAndPass={listInToAndPass}
                        masterProjects={masterProjects}
                        setProject={setProject}
                        project={project}
                        finFo={finFo}
                        isBranchFromADTitle={isBranchFromADTitle}
                        checkedLeaveTemplate={checkedLeaveTemplate}
                        leaveTypeTable={leaveTypeTable}
                        isTextFromValue={isTextFromValue}
                        setTextFromValue={setTextFromValue}
                        textFromValue={textFromValue}
                        refTempSelected={refTempSelected}
                        setRefTempSelected={setRefTempSelected}
                        setSearchRefDocData={setSearchRefDocData}
                        searchRefDocData={searchRefDocData}
                        refAttribute={refAttribute}
                        setSelectedTemplateVersion={setSelectedTemplateVersion}
                        selectedTemplateVersion={selectedTemplateVersion}
                        templateListVersion={templateListVersion}
                        isTemplateVersion={isTemplateVersion}
                        dialogVisibleInRefTemplate={dialogVisibleInRefTemplate}
                        setDialogVisibleInRefTemplate={
                          setDialogVisibleInRefTemplate
                        }
                        getLineApproveForAmount={getLineApproveForAmount}
                        autoNumFormat={autoNumFormat}
                        lineApproval={lineApproval}
                        t={t}
                        actionAlert={actionAlert}
                        setButtonType={setButtonType}
                        setEmployeeList={setEmployeeList}
                        employeeList={employeeList}
                        edFont={rishFont}
                        defaultFont={defaultFont}
                      />
                    )}
                    {selectedView === "3" && (
                      <LineApprovalsComponentFix
                        lineApproval={lineApproval}
                        setLineApproval={setLineApproval}
                        employeeList={masterEmployee}
                        signatureList={masterSignature}
                        canEditLineApproval={canEditLineApprove}
                        currentLevel={memoDetail.current_approval_level}
                        memoDetail={memoDetail}
                        t={t}
                        setEmployeeList={setEmployeeList}
                        employee={employeeList}
                        getLineApproveForAmount={getLineApproveForAmount}
                      />
                    )}
                    {selectedView === "4" && (
                      <AttachmentComponent
                        masterATDLFT={masterATDLFT}
                        listFileAttachDetails={listFileAttachDetails}
                        setListFileAttachDetails={setListFileAttachDetails}
                        setMemoDetail={setMemoDetail}
                        memoDetail={memoDetail}
                        userData={userData}
                        canEditDoc={canEditDoc}
                        listFormNames={listFormNames}
                        checkActionPage={checkActionPage}
                        t={t}
                      />
                    )}
                    {checkActionPage === "edit" && selectedView === "5" && (
                      <HistoryComponent
                        listHistoryDetails={listHistoryDetails}
                        listLineApprove={lineApproval}
                        t={t}
                      />
                    )}
                  </motion.div>
                </AnimatePresence>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RequestScreenFix;
