/**
 * PDFPreviewComponent
 * component ที่ใช้ preview PDF file ที่ไม่ใช่ iframe
 * โดย PDF จะถูกอ่านด้วน lib PDF.JS ของ Mozilla
 * แล้วทำการวาดลง Canvas พร้อมป้องกันคลิกขวา (context menu)
 */

import React, { ReactElement, useEffect, useState } from "react";
import "./PDFPreviewComponent.css";
import { PDFDocumentProxy } from "pdfjs-dist";

export function PDFPreviewComponent(props: { src: string }) {
  const [element, setElement] = useState<ReactElement[]>([]);
  const pdfjs = (window as any).pdfjsLib;
  useEffect(() => {
    let loadingTask = pdfjs.getDocument(props.src);
    loadingTask.promise.then(function (pdf: any) {
      let pv: ReactElement[] = [];
      for (let i = 1; i <= pdf.numPages; i++) {
        pv.push(
          <div key={"page-" + i} className="page">
            <canvas
              key={"canvas-page-" + i}
              ref={(node) => renderPage(node, pdf, i)}
              role="presentation"
            ></canvas>
          </div>
        );
      }
      setElement(pv);
    });
  }, []);
  function renderPage(
    canvas: HTMLCanvasElement | null,
    pdf: PDFDocumentProxy,
    pageNum: number
  ) {
    if (canvas == null) {
      return;
    }

    pdf.getPage(pageNum).then(
      (page) => {
        const scale = 1.5;
        let viewport = page.getViewport({ scale: scale * 2 });

        // Support HiDPI-screens.
        const outputScale = window.devicePixelRatio || 1;
        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        canvas.style.width = Math.floor(viewport.width / 2) + "px";
        canvas.style.height = Math.floor(viewport.height / 2) + "px";

        let context = canvas.getContext("2d");

        let transform =
          outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

        let renderContext = {
          intent: "print",
          canvasContext: context,
          transform: transform,
          viewport: viewport,
        };
        let renderTask = page.render(renderContext as any);
        renderTask.promise.then(
          () => {},
          (reason) => {
            console.error(reason);
          }
        );
      },
      (reason) => {
        console.error(reason);
      }
    );
  }

  function disableContextMenu(e: React.MouseEvent) {
    e.preventDefault();
  }

  return (
    <>
      <div className="pdf-preview-container" onContextMenu={disableContextMenu}>
        {element}
      </div>
    </>
  );
}
