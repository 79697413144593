import React, { useEffect, useMemo, useState } from "react";
import "../PDFTemplateComponent/PDFTemplateComponent.css";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css"; // CSS for react-grid-layout
import "react-resizable/css/styles.css"; //
import usePDFEditor from "../../../hooks/usePDFEditor";
import {
  IControlLayout,
  ILayoutTemplate,
} from "../../../Context/PDFEditorContext";
import StaticInput from "./StaticInput";
import StaticImage from "./StaticImage";
import { FileImageOutlined } from "@ant-design/icons";
import img from "../../../assets/wolf-slide-left.png";

interface props {
  name: "header" | "body" | "footer" | "content";
  children: IControlLayout[];
  paperWidth: number;
}

const DropableComponent = ({ name, paperWidth, children }: props) => {
  const [rowHeight, setRowHeight] = useState<number>(14);
  const [previewImage, setPreviewImage] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  var _userData = JSON.parse(window.localStorage.getItem("userData") || "");
  const _sharepointSiteURL = _userData.SharepointSiteURL;

  const {
    paperLayout,
    addLayout,
    setPaperLayout,
    pageProperty,
    onSelectChange,
    selectionBlock,
    removeSelectedChild,
  } = usePDFEditor();

  const tableDisplay = (layout: IControlLayout) => {
    let node: React.ReactNode[] = [
      <tr>
        {" "}
        {layout.template.attribute?.column?.map((e) => {
          return <th>{e.label}</th>;
        })}
      </tr>,
    ];
    // console.log(layout.h * pageProperty.gridSize);

    return node;
  };

  useEffect(() => {
    const handleKeyDown = (event: { keyCode: number }) => {
      if (event.keyCode === 46) {
        removeSelectedChild(name);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectionBlock]);

  const renderContent = (layout: IControlLayout) => {
    const isStaticText = layout.template.type === "staticText";
    const isSelected = selectionBlock?.i === layout.i;

    if (layout.template.type === "tb") {
      return (
        <table
          className="content"
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid",
          }}
        >
          {tableDisplay(layout)}
        </table>
      );
    } else if (isStaticText) {
      return isSelected ? (
        <StaticInput
          template={layout.template}
          h={layout.h}
          onBlur={(val) => {
            if (val !== null) {
              setPaperLayout([
                ...paperLayout.map((e) => {
                  if (e.i === name) {
                    e.children = e.children?.map((child) => {
                      if (child.i === selectionBlock.i) {
                        child.template.label = val;
                      }

                      return child;
                    });
                  }
                  return e;
                }),
              ]);
            }
          }}
        />
      ) : (
        <span
          className="content"
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            fontFamily: layout.template.attribute?.fontFamily,
            overflow: "unset",
            margin: "unset",
          }}
        >{`${layout?.template?.label}`}</span>
      );
    } else if (layout.template.type === "staticImage") {
      return isSelected ? (
        <StaticImage
          template={layout.template}
          onUploadComplete={(val) => {
            if (val !== null) {
              setPaperLayout([
                ...paperLayout.map((e) => {
                  if (e.i === name) {
                    e.children = e.children?.map((child) => {
                      if (child.i === selectionBlock.i) {
                        child.template.value = val;
                      }

                      return child;
                    });
                  }
                  return e;
                }),
              ]);
            }
          }}
        />
      ) : (
        <>
          {layout.template.value === "" ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#999999",
              }}
            />
          ) : (
            <div
              className="content"
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: `url(${getLink(layout.template.value)})`,
                backgroundSize:
                  layout.template.attribute?.backgroundSize || "cover", // or 'contain' depending on your requirements
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
              onClick={async () => {
                setSelectedImg(getLink(layout.template.value));
                setPreviewImage(true);
              }}
            />
          )}
        </>
      );
    } else if (layout.template.type === "l") {
      return (
        <span
          className="content"
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            fontFamily: layout.template.attribute?.fontFamily,
            overflow: "unset",
            margin: "unset",
          }}
        >{`${
          layout.template.attribute?.hideLabel ? "" : layout.template.label
        }`}</span>
      );
    } else {
      return (
        <span
          className="content"
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            fontFamily: layout.template.attribute?.fontFamily,
            overflow: "unset",
            margin: "unset",
          }}
        >{`${
          layout.template.attribute?.hideLabel
            ? ""
            : layout.template.label + " : "
        }  ${layout?.template?.attribute?.default || "value"}`}</span>
      );
    }
  };

  const getLink = (val: string) => {
    let imgUrl = "";
    const protocol = window.location.protocol;

    if (_sharepointSiteURL) {
      imgUrl = _sharepointSiteURL + "/" + val;
    } else {
      const _port = window.location.port;
      let _tinyURL = _userData.TinyURL;
      if (_port) {
        _tinyURL = _userData.TinyURL + ":" + _port;
      }
      imgUrl = `${protocol}//` + _tinyURL + val;
    }

    return imgUrl !== ""
      ? imgUrl
      : "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png";
  };

  const childrenLayout = useMemo(() => {
    return children.map((layout, idx) => {
      const isSelected = selectionBlock?.i === layout.i;

      return (
        <div
          className={`custom-grid child-grid`}
          title={layout.i}
          key={layout.i}
          data-grid={{
            i: layout.i,
            x: layout.x,
            y: layout.y,
            w: layout.w,
            h: layout.h,
            minW: layout?.minW,
            minH: layout?.minH,
            static: layout.static,
            resizeHandles: ["n", "ne", "nw", "s", "sw", "se"],
            isDraggable: isSelected, // Make it draggable if selected
            isResizable: layout.template?.type === "tb" ? false : isSelected, // Make it resizable if selected
            isBounded: true,
          }}
          style={{
            display: "flex",
            border: `${selectionBlock?.i === layout.i ? " 2px solid" : ""}`,
            alignItems: layout.template.attribute?.vAlign,
            justifyContent: layout.template.attribute?.hAlign,
            color: layout.template.attribute?.color,
            fontFamily: layout.template.attribute?.fontFamily,
            fontSize: layout.template.attribute?.fontSize,
            textDecoration: layout.template.attribute?.isUnderlined
              ? "underline"
              : "unset",
            fontStyle: layout.template.attribute?.isItalic
              ? "italic"
              : "normal",
            fontWeight: layout.template.attribute?.isBold ? 700 : 100,
            overflow:
              selectionBlock?.template.type === "tb" ? "hidden" : "unset",
            textAlign:
              layout.template.attribute?.hAlign === "flex-start"
                ? "left"
                : layout.template.attribute?.hAlign === "flex-end"
                ? "right"
                : "center",
          }}
          onClick={() => {
            onSelectChange(layout);
          }}
        >
          {renderContent(layout)}
        </div>
      );
    });
  }, [paperLayout, selectionBlock]);

  return (
    <>
      {" "}
      <GridLayout
        className="inner-layout"
        style={{ minHeight: rowHeight }}
        cols={Math.round(paperWidth / pageProperty.gridSize)}
        rowHeight={pageProperty.gridSize}
        isDroppable={true}
        isResizable
        isDraggable
        width={paperWidth}
        containerPadding={[pageProperty.padding, 0]}
        margin={[0, 0]}
        allowOverlap
        preventCollision
        isBounded
        layout={children}
        onDragStop={(layouts) => {
          setPaperLayout([
            ...paperLayout.map((e) => {
              if (e.i === name) {
                e.children = e.children?.map((child) => {
                  const layout = layouts.find((layout) => layout.i === child.i);
                  if (layout) {
                    child.x = layout.x;
                    child.y = layout.y;
                  }
                  return child;
                });
              }
              return e;
            }),
          ]);
        }}
        onResizeStop={(layouts) => {
          if (!pageProperty.isDragingFromOutSide) {
            setPaperLayout([
              ...paperLayout.map((e) => {
                if (e.i === name) {
                  e.children = e.children?.map((child) => {
                    const layout = layouts.find(
                      (layout) => layout.i === child.i
                    );

                    if (layout) {
                      child.w = layout.w;
                      child.h = layout.h;
                    }

                    return child;
                  });
                }
                return e;
              }),
            ]);
          }
        }}
        onDrop={(layouts, item: any, e: any) => {
          try {
            const data: ILayoutTemplate = JSON.parse(
              e.dataTransfer.getData("application/json")
            );
            addLayout(name, item, data);
          } catch (error) {
            console.error(error);
          }
        }}
      >
        {childrenLayout}
      </GridLayout>
    </>
  );
};
export default DropableComponent;
