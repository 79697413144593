export enum LogicType {
  DataSourceRelated = "datasourcerelated",
  Reference = "reference",
  DataSourceLoad = "datasourceload",
  DataLineApprove = "datalineapprove",
  DataRelatedToLoadData = "datareladtoloaddata",
  DataAjaxLoadTable = "dataajaxloadtable",
  Permission = "Permission",
  Role = "role",
  RefInfomation = "refinfomation",
}
