import React, { useState, useEffect, useMemo } from "react";
import "./LoginScreen.css";
import { Carousel, Spin } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
// import WolfLogo from "../../assets/WOLF_lite_logo_TCB_Blue.png";
import { useForm, Controller } from "react-hook-form";
import { useSessionContext } from "../../Context/AuthContext";
import { loginWolfAccount, loginWolfBD } from "../../Services/LoginService";
import { AiOutlineMail } from "react-icons/ai";
import { MdOutlineLock } from "react-icons/md";
import { useUserContext } from "../../Context/UserContext";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { deleteAllCookies } from "../../Helper/DeleteAllCookie";
import { clearLocalStorageOnLogout } from "../../Helper/localStoreFunction";
import useLoading from "../../hooks/useLoading";
interface Props {
  PathLogo?: string;
  PathCarousel?: string[];
  PathCodeUrl?: any;
}

export const LoginScreenOpenId = (props: Props) => {
  // const [onLoading, setOnLoading] = useState<boolean>(false);
  const { isLoad, setLoad } = useLoading();

  const [remarkValid, setRemarkValid] = useState("");
  const [carouselItem] = useState<any>(props?.PathCarousel);
  const [sessionState, setSessionState] = useSessionContext();
  let location = useLocation();
  let history = useHistory();
  const { i18n } = useTranslation(["translation"]);
  const [userData, setUserData] = useUserContext();

  const cookies = new Cookies();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  useEffect(() => {
    // console.log("codeUrl=>", props.PathCodeUrl);
    setLoad(false);
    GetOpenId();
  }, [props.PathCodeUrl]);

  const onSubmitLoginOpenId = async () => {
    let _loginOpenId = await fetch("api/Login/Authorize/OpenId").then((res) =>
      res.json()
    );
    window.location.href = _loginOpenId.redirect;
  };

  const GetOpenId = async () => {
    let _loginOpenId = await fetch(
      "api/Login/Authorize/CallBack?code=" + props.PathCodeUrl
    ).then((res) => res.json());
    // console.log("loginCallbackkkkkkkkkkkkkkkkkkkkkkkkk=>", _loginOpenId);
    clearLocalStorageOnLogout();
    localStorage.removeItem("tinyUrl");
    cookies.remove("GuidVerify");
    cookies.set("GuidVerify", _loginOpenId.GuidVerify, {
      path: "/",
      expires: new Date(Date.now() + 86400000),
    });
    ActionPassLogin(_loginOpenId);
  };

  const ActionPassLogin = async (_loginOpenId: any) => {
    if (_loginOpenId === false) {
      clearLocalStorageOnLogout();
      deleteAllCookies();
      // history.push("/login");
      setRemarkValid("ไม่พบผู้ใช้ในระบบกรุณาลองใหม่อีกครั้ง");
      // setOnLoading(false);
      return;
    }
    const fav_storage = localStorage.getItem("favorite")
      ? JSON.parse(localStorage.getItem("favorite") || "")
      : "";
    if (fav_storage !== null) {
      if (fav_storage.length > 0) {
        localStorage.setItem("favorite", JSON.stringify(fav_storage));
      }
    } else {
      localStorage.setItem("favorite", JSON.stringify([]));
    }
    if (_loginOpenId?.employeeData?.Lang === "TH") {
      i18n.changeLanguage("th");
    } else {
      i18n.changeLanguage("en");
    }
    setUserData(_loginOpenId?.employeeData);
    localStorage.setItem("userData", JSON.stringify(_loginOpenId));
    localStorage.setItem("employeeId", _loginOpenId.employeeData.EmployeeId);
    localStorage.setItem(
      "employeeCode",
      _loginOpenId.employeeData.EmployeeCode
    );
    localStorage.setItem("lang", _loginOpenId.employeeData.Lang);
    localStorage.setItem("nameEn", _loginOpenId.employeeData.NameEn);
    localStorage.setItem("nameTh", _loginOpenId.employeeData.NameTh);
    localStorage.setItem("email", _loginOpenId.employeeData.Email);
    localStorage.setItem(
      "positionNameTh",
      _loginOpenId.employeeData.PositionNameTh
    );
    localStorage.setItem(
      "positionNameEn",
      _loginOpenId.employeeData.PositionNameEn
    );
    localStorage.setItem(
      "departmentId",
      _loginOpenId.employeeData.DepartmentId
    );
    localStorage.setItem(
      "departmentNameTh",
      _loginOpenId.employeeData.DepartmentNameEn
    );
    localStorage.setItem("tinyUrl", _loginOpenId.TinyURL);

    setSessionState({ ...sessionState, isAuthenticated: true });
    // setOnLoading(false);
    if (
      localStorage.getItem("memoid") &&
      localStorage.getItem("memoid") !== ""
    ) {
      history.push("/Request?MemoID=2065");
      localStorage.removeItem("memoid");
    } else {
      history.push("/Default");
    }
  };

  return (
    <>
      {props.PathCarousel && props.PathLogo && (
        <div className="login-screens">
          <div className="login-container">
            <div className="image-slider-container">
              <Carousel autoplay className="img-carousel">
                {carouselItem.map((_carousel: any, idx: number) => {
                  return (
                    <div className="slide-content">
                      <img src={_carousel} alt="slide" />
                    </div>
                  );
                })}
              </Carousel>
            </div>

            <form onSubmit={handleSubmit(onSubmitLoginOpenId)}>
              <div>
                <div className="right-card-container">
                  <img
                    src={props?.PathLogo}
                    alt="logo-login"
                    className="img-logo"
                  />
                  <div className="button-container">
                    <button className="login-button" type="submit">
                      Log In
                    </button>
                    <p className="text-incorrect">{remarkValid}</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {props.PathCodeUrl && (
        <div className="login-screens">
          <div
            style={{
              display: "flex",
              backgroundColor: "#fff",
              width: "50%",
              height: "50%",
              borderRadius: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Spin size="large" />
              <p className="text-wait">กำลังเข้าสู่ระบบ</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
