import { Input, InputNumber } from "antd";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

type Props = {
  footerAtt: any;
  onChange: (changeObj?: any) => void;
  haveInput: boolean;
};

const TableFooter = ({ footerAtt, onChange, haveInput }: Props) => {
  const [inputValue, setInputValue] = useState(footerAtt.value);
  console.log(haveInput);

  return (
    <div className={"footer-layout"}>
      <Row>
        <Col
          xs={haveInput ? 8 : 10}
          sm={haveInput ? 8 : 10}
          xl={haveInput ? 8 : 10}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {footerAtt.label + " : "}
        </Col>
        {footerAtt.useValue ? (
          <>
            {" "}
            <Col xs={2} sm={2} xl={2}>
              <InputNumber
                className="input-component"
                placeholder={footerAtt.label + " " + "input"}
                style={{ width: "100%" }}
                size={"large"}
                value={inputValue}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                onChange={(e) => {
                  setInputValue(e);
                }}
                onBlur={(e) => {
                  if (Number(e.target.value)) {
                    console.log(footerAtt);

                    let dup = { ...footerAtt };
                    dup.value = Number(e.target.value);
                    onChange(dup);
                  }
                }}
                controls={false}
              />
            </Col>
            <Col xs={2} sm={2} xl={2}>
              <Input
                readOnly
                size={"large"}
                placeholder={footerAtt.label}
                addonAfter={footerAtt.symbol}
                value={footerAtt.result}
              />
            </Col>
          </>
        ) : (
          <>
            <Col
              xs={haveInput ? 4 : 2}
              sm={haveInput ? 4 : 2}
              xl={haveInput ? 4 : 2}
            >
              <Input
                readOnly
                size={"large"}
                placeholder={footerAtt.label}
                addonAfter={footerAtt.symbol}
                value={footerAtt.result}
              />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default TableFooter;
