import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { useUserContext } from "../../../Context/UserContext";
import { InputTextComponents } from "../../InputTextComponents/InputTextComponents";
import { Tooltip } from "primereact/tooltip";
import { Col, Row, Switch } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { ButtonComponents } from "../../ButtonComponents/ButtonComponents";
import { Button } from "primereact/button";

interface Props {
  dataList: any;
  dialogKey: any;
  onSelectFunc?: (rowData: any) => void;
  columns: IColumn[];
  dialogVisible: boolean;
  setDialogVisible: (visible: boolean) => void;
  selectionMode?: "single" | "multi";
  loading?: boolean;
  customFooter?: any;
  selectionData?: any[];
  setSelectedData?: (val: any[]) => void;
  mutiable?: boolean;
}

export interface IColumn {
  field: string;
  headerEn: string;
  headerTh?: string;
}

export const SelectDataDialog = (props: Props) => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [searchData, setSearchData] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState<any[]>([]);
  const [userData, setUserData] = useUserContext();
  const tooltipRef = useRef<any>(null);
  const [multiSwitch, setMultiSwitch] = useState<boolean>(false);
  const [selectedDatas, setSelectedData] = useState<any[]>([]);

  useEffect(() => {
    if (props.dialogVisible) {
      let keys: any[] = [];
      Object.keys(props.dataList[0] ? props.dataList[0] : "").map(
        (key: string, idx: number) => {
          if (!key.toLowerCase().includes("id") && key !== "SignPicPath") {
            keys.push(key);
          }
        }
      );
      setSearchKey([...keys]);
      setGlobalFilterValue("");
      setSearchData(props.dataList);
    }
  }, [props.dialogVisible, props.dataList]);

  function toggleModal() {
    props.setDialogVisible(!props.dialogVisible);
  }

  const renderHeader = () => {
    return (
      <Row gutter={[5, 5]} align="middle">
        <Col>
          <InputTextComponents
            setClassNameProps={"set-input-search-dialog"}
            valueProps={globalFilterValue}
            onChangeProps={onGlobalFilterChange}
            placeholderProps={"Search"}
            setIconProps={<i className="pi pi-search" />}
            setClassNameSpanProps={"p-input-icon-left"}
          />
        </Col>
        {props.mutiable && (
          <Col
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <span>Multi select</span>
            <span>
              <Switch
                key={"multiswitch"}
                checked={multiSwitch}
                onChange={() => {
                  setSelectedData([]);
                  setMultiSwitch(!multiSwitch);
                }}
              />
            </span>
          </Col>
        )}
      </Row>
    );
  };

  // useEffect(() => {
  //   tooltipRef.current && tooltipRef.current.updateTargetEvents();
  // }, [searchData]);
  const dynamicColumns = props.columns.map((col, i) => {
    if (col.field !== "Memo_MAdvancveForm") {
      return (
        <Column
          key={col.field}
          header={
            <>
              <p className="row headtext">{col.headerEn}</p>
              <p className="row subtext">{col.headerTh}</p>
            </>
          }
          body={(rowData) => {
            let newField = "";
            if (userData.Lang === "EN") {
              newField = col.field?.replace("Th", "En");
              // return rowData[newField];
            } else {
              newField = col.field?.replace("En", "Th");
              // return rowData[newField];
            }

            return (
              <div
                className="tooltip-data"
                data-pr-tooltip={"EmployeeId : " + rowData.EmployeeId}
                style={{ display: "flex", justifyContent: "space-between" }}
                onMouseOver={() => {
                  if (props.dialogKey === "Employee") {
                    tooltipRef.current &&
                      tooltipRef.current.updateTargetEvents();
                  }
                }}
              >
                <span>{rowData[newField]}</span>
              </div>
            );
          }}
        />
      );
    }
  });

  const onGlobalFilterChange = (e: any) => {
    try {
      const value = e;
      const data = props.dataList.filter((data: any) => {
        let findData: any = [];
        searchKey.map((col: any) => {
          let field = col;
          if (data[field]) {
            if (
              data[field]
                .toString()
                .toLowerCase()
                .indexOf(value.toLowerCase()) !== -1
            ) {
              findData.push(data);
            }
          }
        });
        if (findData.length > 0) {
          return true;
        }
      });
      setSearchData([...data]);
      setGlobalFilterValue(value);
    } catch (error) {
      console.log("table=>search=>error", error);
    }
  };

  return (
    <>
      <Tooltip
        ref={tooltipRef}
        position={"top"}
        mouseTrack
        mouseTrackLeft={0}
        mouseTrackTop={1}
        autoHide={false}
        target={".p-selectable-row .tooltip-data"}
      />

      <Dialog
        key={props.dialogKey}
        header={renderHeader}
        visible={props.dialogVisible}
        style={{ width: "80%", borderRadius: "16px" }}
        onHide={toggleModal}
        onShow={() => {
          setSelectedData([]);
          setMultiSwitch(false);
        }}
        footer={props.customFooter}
        draggable={false}
        resizable={false}
        blockScroll
        closable={true}
      >
        {!props.loading ? (
          <>
            <>
              <DataTable
                paginator
                rows={5}
                value={searchData}
                selectionMode={multiSwitch ? "multiple" : "single"}
                loading={props.loading}
                tableStyle={{ borderColor: "#e6e6e6" }}
                dataKey={props.dialogKey + "Id"}
                responsiveLayout="scroll"
                selection={selectedDatas}
                onRowSelect={(e: any) => {
                  if (multiSwitch) return;

                  if (tooltipRef.current) {
                    tooltipRef.current.state.visible = false;
                  }

                  if (props.selectionMode === "multi") {
                    if (props.onSelectFunc) {
                      props.onSelectFunc([e.data]);
                    }
                  } else {
                    if (props.onSelectFunc) {
                      props.onSelectFunc(e.data);
                    }
                  }
                }}
                onSelectionChange={(e) => setSelectedData(e.value)}
                sortField="EmployeeCode"
                sortOrder={1}
              >
                {multiSwitch && (
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3em" }}
                  ></Column>
                )}

                {dynamicColumns}
              </DataTable>
            </>
            <>
              {multiSwitch && (
                <>
                  <Row
                    style={{
                      justifyContent: "right",
                      paddingTop: "5px",
                      paddingRight: "10px",
                    }}
                  >
                    {" "}
                    <Button
                      className="memo-button submit"
                      label="Save"
                      aria-label="Search"
                      // style={{ minWidth: "unset", textAlign: "center" }}
                      onClick={() => {
                        if (selectedDatas.length > 0) {
                          if (props.selectionMode === "multi") {
                            if (props.onSelectFunc) {
                              props.onSelectFunc(selectedDatas);
                            }
                          } else {
                            selectedDatas.forEach((selectedData) => {
                              if (props.onSelectFunc) {
                                props.onSelectFunc(selectedData);
                              }
                            });
                          }

                          setSelectedData([]);
                          setMultiSwitch(!multiSwitch);
                        }
                      }}
                      style={{
                        minWidth: "unset",
                        textAlign: "center",
                        height: "38px",
                        background: " #28a745",
                        border: "1px solid #28a745",
                        color: "#ffffff",
                        fontSize: "13px",
                        borderRadius: "6px",
                        padding: 5,
                        // margin: " 0px 11px 0px 0px",
                      }}
                    />
                  </Row>
                </>
              )}
            </>
          </>
        ) : (
          <>
            {" "}
            <DataTable
              paginator
              rows={5}
              value={[]}
              tableStyle={{ borderColor: "#e6e6e6" }}
              responsiveLayout="scroll"
              loading={props.loading}
            >
              {dynamicColumns}
            </DataTable>
          </>
        )}
      </Dialog>
    </>
  );
};
