import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import React from "react";
import "../SimLineApproveScreen.css";
import { Col,Row } from "antd";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
interface Props {}

export const InitialComponent = (props: Props) => {
  const dataAutoApprove = [
    { nameEn: "First Step Approve", nameTh: "อนุมัติเฉพาะขั้นแรก" },
    { nameEn: "Last Step Approve", nameTh: "อนุมัติเฉพาะขั้นสุดท้าย" },
  ];
  return (
    <>
    <Row gutter={[16, 16]}>
       <Col xs={24} lg={5} xl={6}>
          <div className="text-header-css-sim-line-component">
            Requester (Username)
          <InputText className="set-input-component-css" />
          </div>
      </Col>
      <Col xs={24} lg={5} xl={6}>
          <div className="text-header-css-sim-line-component">
          Email (Ex. @xxx.com)
          <InputText className="set-input-component-css" />
          </div>
      </Col>
      <Col xs={24} lg={5} xl={6}>
          <div className="text-header-css-sim-line-component">
          Name
          <InputText className="set-input-component-css" />
          </div>
      </Col>
      <Col xs={24} lg={5} xl={6}>
          <div className="text-header-css-sim-line-component">
          Position Th
          <InputText className="set-input-component-css" />
          </div>
      </Col>
    </Row>
    <div className="row text-header-css-sim-line">Report To</div>
    <DataTable  tableStyle={{ minWidth: '100%'}}  size="small">
      <Column 
          header={
            <div>
              <span className="row headtext">Control Label</span>
              <span className="row subtext">ชื่อ</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Type</span>
              <span className="row subtext">ชนิด</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Value</span>
              <span className="row subtext">ค่า</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Delete</span>
              <span className="row subtext">ลบ</span>
            </div>
          }
        />
    </DataTable>
    <Row gutter={[16, 16]}>
       <Col xs={24} lg={5} xl={8}>
          <div className="text-header-css-sim-line-component">
            Template Name
          <InputText className="set-input-component-css" />
          </div>
      </Col>
      <Col xs={24} lg={5} xl={8}>
          <div className="text-header-css-sim-line-component">
            Template ID
          <InputText className="set-input-component-css" />
          </div>
      </Col>
      <Col xs={24} lg={5} xl={8}>
          <div className="text-header-css-sim-line-component">
            Total Amount
          <InputText className="set-input-component-css" />
          </div>
      </Col>
    </Row>
    <Row>
      <Col xs={24} lg={12} xl={12}>
      <div className="text-header-css-sim-line-component ">Redundant Approver</div>
        <div>
        <Checkbox value="New York"></Checkbox>
        <label className="p-checkbox-label set-padding-css-left-sim-line">
            Auto Approve By System
        </label>
        </div>
      </Col>
      <Col xs={24} lg={12} xl={12}>
      <div className="text-header-css-sim-line-component ">Auto Approve</div>
        <div>
        <div className="set-css-head-auto-approve">
          {dataAutoApprove.map((_data, i) => {
            return (
              <div
                 key={i}
                  className="field-radiobutton display-flex-sim-line "
              >
                <RadioButton name="category" value={_data} />
                  <label className="">
                    {_data.nameEn}
                       <br />
                          {_data.nameTh}
                  </label>
                  </div>
                        );
                      })}
            </div>
        </div>
      </Col>
    </Row>
    <div className="row text-header-css-sim-line">Approval Matrix</div>
    <DataTable  tableStyle={{ minWidth: '100%'}}  size="small">
      <Column 
          header={
            <div>
              <span className="row headtext">Seq</span>
              <span className="row subtext">ลำดับ</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Control Label</span>
              <span className="row subtext">ชื่อ</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Type</span>
              <span className="row subtext">ชนิด</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Value</span>
              <span className="row subtext">ค่า</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Delete</span>
              <span className="row subtext">ลบ</span>
            </div>
          }
        />
    </DataTable>
    <div className="row text-header-css-sim-line">Approval Matrix Detail</div> 
    <DataTable  tableStyle={{ minWidth: '100%'}}  size="small">
      <Column 
          header={
            <div>
              <span className="row headtext">Seq</span>
              <span className="row subtext">ลำดับ</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Control Label</span>
              <span className="row subtext">ชื่อ</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Type</span>
              <span className="row subtext">ชนิด</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Value</span>
              <span className="row subtext">ค่า</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Delete</span>
              <span className="row subtext">ลบ</span>
            </div>
          }
        />
    </DataTable>
    <div className="row text-header-css-sim-line">Specific Approvals</div>
    <DataTable  tableStyle={{ minWidth: '100%'}}  size="small">
      <Column 
          header={
            <div>
              <span className="row headtext">Seq</span>
              <span className="row subtext">ลำดับ</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Control Label</span>
              <span className="row subtext">ชื่อ</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Type</span>
              <span className="row subtext">ชนิด</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Value</span>
              <span className="row subtext">ค่า</span>
            </div>
          }
        />
        <Column
          header={
            <div>
              <span className="row headtext">Delete</span>
              <span className="row subtext">ลบ</span>
            </div>
          }
        />
    </DataTable>
    </>
  );
};
