enum LoadState {
  IDLE = 0,
  LOADING = 1,
  COMPLETE = 2,
  FAIL = 4,
}
class GetAllMasterDataService {
  private static data: any = null;
  private static error: any = null;
  private static state: LoadState = LoadState.IDLE;
  private static queue: { resolve: Function; reject: Function }[] = [];

  private static responseToQueue() {
    if (this.state == LoadState.COMPLETE) {
      this.queue.forEach(({ resolve }) => {
        resolve(this.data);
      });
    } else if (this.state == LoadState.FAIL) {
      this.queue.forEach(({ reject }) => {
        reject(this.error);
      });

      this.state = LoadState.IDLE;
      this.error = null;
      this.data = null;
    }
  }

  public static retrieve() {
    return new Promise<any>(async (resolve, reject) => {
      try {
        // add callback to queue
        this.queue.push({ resolve, reject });

        if (this.state == LoadState.IDLE) {
          this.state = LoadState.LOADING;
          this.data = await fetch("/api/MasterData/GetAll").then((r) =>
            r.json()
          );
          this.state = LoadState.COMPLETE;
        }
      } catch (e) {
        this.error = e;
        this.state = LoadState.FAIL;
      }

      this.responseToQueue();
    });
  }
}

export const GetAllMasterData = () => {
  return GetAllMasterDataService.retrieve();
};

export const GetSignature = async () => {
  const respone = await fetch("/api/MasterData/GetSignature")
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});

  return respone.filter((e: any) => e.isActive === true);
};
export const GetReportSetting = async () => {
  const respone = await fetch("/api/MasterData/GetReportSetting")
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});

  return respone.filter((e: any) => e.isActive === true);
};
export const GetIsDocControl = async () => {
  const respone = await fetch("/api/MasterData/GetIsDocControl")
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return respone;
};
export const getAuthorization = async () => {
  const respone = await fetch("/api/MasterData/getAuthorization")
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});
  return respone;
};
export const getVersion = async () => {
  const respone = await fetch("/api/MasterData/getVersion")
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});
  return respone;
};
export const getVersionTempVC = async () => {
  const respone = await fetch("/api/MasterData/getVersionTempVC")
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});
  return respone;
};

export const GetLeaveTemplate = async () => {
  const respone = await fetch("api/MasterData/GetLeaveTemplate")
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
  return respone;
};

export const GetLeaveTemplateByEmpId = async (empId: number) => {
  const respone = await fetch(`api/MasterData/GetLeaveTemplate/${empId}`)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
  return respone;
};

export const GetMasterDataFieldInfo = async () => {
  const respone = await fetch("api/MasterData/FieldInfo")
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
  return respone;
};

export const GetMasterDataReportTem = async () => {
  const respone = await fetch("api/MasterData/GetReportTem")
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
  return respone;
};

export const GetFontList = async () => {
  const respone = await fetch("api/MasterData/FontList")
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
  return respone;
};

export const GetMasterDataBrowserAgent = async () => {
  return fetch("api/MasterData/GetBrowserAgent")
    .then((response) => response.json() as Promise<any[]>)
    .then((data: any[]) => {
      if (data.length > 0) {
        let mstData = {
          browsers: (data[0].value1 || "")
            .split("|")
            .map((x: string) => x.trim().toLowerCase()),
          messages: (data[0].value2 || "")
            .split("|")
            .map((x: string) => x.trim()),
        };
        return mstData;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.error(GetMasterDataBrowserAgent.name, ":", err);
      return Promise.reject(err);
    });
};
