import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { BiSave } from "react-icons/bi";
import { IoCloseOutline } from "react-icons/io5";
import { ButtonComponents } from "../../ButtonComponents/ButtonComponents";
import { TextHeaderComponents } from "../../TextHeaderComponents/TextHeaderComponents";
import "../../DataFechDialogComponents/ReferenceDocumentDialog/ReferenceDocumentDialog.css";
import "../../DataFechDialogComponents/ReferenceDocumentDialog/TableTemplateComponents/TableTemplateComponents.css";
import "./ReferenceInformationComponents.css";

type Props = {
  visibleRefenceDocumentDialog: boolean;
  setVisibleRefenceDocumentDialog: (val: boolean) => void;
  advanceForm: any;
  controlModel: any;
  setControlModelObj?: any;
  listRefInformationProps: any;
  setRefInformation: any;
  refInformation: any;
  refLogic: any;
  dataLogic: any;
  setLogicsRefIn: any;
};
const ReferenceInformationDialog: React.FC<Props> = ({
  visibleRefenceDocumentDialog,
  setVisibleRefenceDocumentDialog,
  setControlModelObj,
  controlModel,
  advanceForm,
  listRefInformationProps,
  refInformation,
  setRefInformation,
  refLogic,
  dataLogic,
  setLogicsRefIn,
  ...props
}) => {
  const [templateSelected, setTemplateSelected] = useState<any>("");
  const [requestSelected, setRequestorSelected] = useState<any>("");
  const [templateField, setTemplateField] = useState<any>("");
  const [requestField, setRequestorField] = useState<any>("");

  const dialogHeader = () => {
    return (
      <Row style={{ borderBottom: "1px solid #cfcfcf" }}>
        <Col xs={12} sm={12} xl={12}>
          <p className="referenceDocumentDialog-dialog-p-textheader">
            Search Reference Document
          </p>
        </Col>
      </Row>
    );
  };

  const dialogFooter = () => {
    return (
      <div className="referenceDocumentDialog-renderFooter-display">
        <ButtonComponents
          setLabelProps="Cancel"
          setIconProps={
            <IoCloseOutline size={"16px"} style={{ marginRight: "3px" }} />
          }
          onClickProps={hide}
          setClassNameProps="p-button-text referenceDocumentDialog-button"
          setStyleProps={{
            height: "38px",
            border: "0.5px solid #FF2626",
            background: "#FFFFFF",
            color: "#FF2626",
            borderRadius: "6px",
            fontSize: "13px",
          }}
        />
        <ButtonComponents
          setLabelProps="Save"
          setIconProps={<BiSave size={"16px"} style={{ marginRight: "3px" }} />}
          onClickProps={() => onSave()}
          setStyleProps={{
            height: "38px",
            borderRadius: "6px",
            border: "1px solid rgb(40, 47, 106)",
            fontSize: "13px",
          }}
        />
      </div>
    );
  };
  useEffect(() => {
    fetchTemplatData();
    fetchRequestorData();
  }, []);

  async function fetchTemplatData() {
    let arrayFliedTest: any = [];
    const _advanceForm = advanceForm.items;
    let label: string;
    let key: string;
    let type: string;

    for (let i = 0; i < _advanceForm.length; i++) {
      let layout = _advanceForm[i].layout;
      for (let j = 0; j < layout.length; j++) {
        let template = layout[j].template;
        if (
          template.type !== "tb" &&
          template.type !== "l" &&
          template.type !== "em" &&
          template.type !== "bt" &&
          template.type !== "d"
        ) {
          label = template.label;
          key = template.label;
          type = template.type;
          arrayFliedTest.push({ label, key, type });
        }
      }
    }
    setTemplateField(arrayFliedTest);
  }

  async function fetchRequestorData() {
    const requestorLabel = [
      { label: "Requestor_Employee_code", key: "Requestor_Employee_code" },
      { label: "Requestor_Name", key: "Requestor_Name" },
      { label: "Requestor_Positon", key: "Requestor_Positon" },
      { label: "Requestor_Division", key: "Requestor_Division" },
      { label: "Requestor_Department", key: "Requestor_Department" },
      { label: "Requestor_Email", key: "Requestor_Email" },
    ];

    setRequestorField(requestorLabel);
  }

  const hide = () => {
    setVisibleRefenceDocumentDialog(!visibleRefenceDocumentDialog);
  };

  const onSave = () => {
    try {
      let refValue;
      refValue = {
        condition: templateSelected.label,
        required: requestSelected.label,
      };

      let arrayRef = refInformation ? refInformation : [];
      arrayRef.push(refValue);
      setRefInformation(arrayRef);
      setVisibleRefenceDocumentDialog(!visibleRefenceDocumentDialog);
      setTemplateSelected("");
      setRequestorSelected("");

      let TemplateLogic: any[] = [];

      let logicControl: any[] = [];
      arrayRef.map((cons: any) => {
        if (
          logicControl.filter((e: any) => e.label === cons.condition).length ===
          0
        ) {
          logicControl.push({
            condition: cons.condition,
            required: cons.required,
          });
        }
      });

      logicControl.map((logic: any, idx: number) => {
        TemplateLogic.push({
          Seq: idx + 1,
          Logictype: "refinfomation",
          Jsonvalue: JSON.stringify(logic),
        });
      });

      setLogicsRefIn([...TemplateLogic]);
      // if (TemplateLogic.length > 0) {
      //   setControlModelObj((prevState: any) => ({
      //     ...prevState,
      //     cMSTTemplateLogic: [...TemplateLogic],
      //   }));
      // }
    } catch (error) {
      console.error("ref=>onSave=>error", error);
    }
  };

  return (
    <>
      <Dialog
        header={dialogHeader}
        draggable={false}
        closable={false}
        visible={visibleRefenceDocumentDialog}
        style={{ width: "70vw" }}
        blockScroll
        baseZIndex={1000}
        footer={dialogFooter}
        //onShow={onShowDialog}
        onHide={hide}
      >
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: 20 }}
          onClick={() => {
            console.log(controlModel, "controlModel");
          }}
        >
          <Row>
            <Col xs={3} sm={3} xl={3}>
              <TextHeaderComponents textHeaderProps={"Selected Reference :"} />
            </Col>
            <Col xs={9} sm={9} xl={9}>
              <Dropdown
                style={{ width: "100%" }}
                id={"Selected Reference"}
                value={templateSelected}
                placeholder="--- Please select ---"
                onChange={(e: any) => {
                  setTemplateSelected(e.value);
                }}
                optionLabel={"label"}
                filter
                showClear
                filterBy={"label"}
                options={templateField}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={3} sm={3} xl={3}>
              <TextHeaderComponents textHeaderProps={"Reference Field :"} />
            </Col>
            <Col xs={9} sm={9} xl={9}>
              <Dropdown
                style={{ width: "100%" }}
                id={"Reference Field"}
                value={requestSelected}
                placeholder="--- Please select ---"
                onChange={(e: any) => {
                  setRequestorSelected(e.value);
                }}
                optionLabel={"label"}
                filter
                showClear
                filterBy={"label"}
                options={requestField}
              />
            </Col>
          </Row>
        </div>
      </Dialog>
    </>
  );
};

export default ReferenceInformationDialog;
